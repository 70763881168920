import React from 'react';
import ContactForm from '../ContactFrom'
import { Link } from 'react-router-dom'


const Contactpage2 = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    // const linkStyle = {
    //     position: 'relative',
    //     fontSize: 'var(--font-16)',
    //     width: '40px !important',
    //     height: '40px !important',
    //     lineHeight: '38px',
    //     textAlign: 'center',
    //     borderRadius: '0px',
    //     border: '1px solid #dddddd',
    //     transition: 'all 0.3s ease',
    //     MozTransition: 'all 0.3s ease',
    //     WebkitTransition: 'all 0.3s ease',
    //     msTransition: 'all 0.3s ease',
    //     OTransition: 'all 0.3s ease',
    // };
    return (

        <section className="contact-page-section">
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="info-column col-lg-4 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="info-box">
                                <div className="box-inner">
                                    <span className="icon flaticon-placeholder"></span>
                                    <h6 style={{color: '#fff'}}>Address</h6>
                                    <div className="text" style={{color: '#c99c18'}}>Burj Al Salam, Trade Center 1, Dubai, UAE.</div>
                                </div>
                            </div>
                            <div className="info-box">
                                <div className="box-inner">
                                    <span className="icon flaticon-email-1"></span>
                                    <h6 style={{color: '#fff'}}>Email</h6>
                                    <a href="mailto:contact@forttuna.co">contact@forttuna.co</a>
                                </div>
                            </div>
                            <div className="info-box">
                                <div className="box-inner">
                                    <span className="icon flaticon-telephone"></span>
                                    <h6 style={{color: '#fff'}}>Phone</h6>
                                    <a href="tel:+971555957093">+971 55 595 7093</a>
                                </div>
                            </div>
                            <div className="info-box">
                                <div className="box-inner">
                                    <span className="icon flaticon-share-1"></span>
                                    <h6 style={{color: '#fff'}}>Social media</h6>
                                    <ul className="social-box">
                                        <a href="https://www.linkedin.com/company/forttunaawards" style={{color:'#fff'}} target='_blank' className="social-box-link youtube fa fa-linkedin"></a>
                                        <a href="https://www.instagram.com/forttunaawards/" style={{color:'#fff'}} target='_blank' className="social-box-link twitter fa fa-instagram"></a>
                                        <a href="https://www.facebook.com/forttunaaward" style={{color:'#fff'}} target='_blank' className="social-box-link facebook fa fa-facebook"></a>
                                        <a href="https://www.youtube.com/@ForttunaAwards" style={{color:'#fff'}} target='_blank' className="social-box-link facebook fa fa-youtube"></a>
                                        <a href="https://x.com/ForttunaAwards" target='_blank' style={{color:'#fff'}} className="social-box-link facebook fa fa-twitter"></a>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="form-column col-lg-8 col-md-12 col-sm-12">
                        <div className="inner-column">
                            <div className="sec-title">
                                {/* <div className="sec-title_title">CONTACT INFO</div> */}
                                <h2 className="sec-title_heading">Message Us</h2>
                            </div>
                            <div className="default-form contact-form">
                                <ContactForm />
                            </div>
                            <div className="map-boxed">
                                <div className="map-outer">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.2150264599723!2d55.28650520000001!3d25.229681600000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f42ef4bcbda43%3A0x996608a1fcf77669!2sBurj%20Al%20Salam%20-%206th%20St%20-%20Trade%20Centre%20-%20Trade%20Centre%201%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1722314491170!5m2!1sen!2sin" allowFullScreen=""></iframe>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </section>
    )

}

export default Contactpage2;
