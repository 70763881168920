import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom'
import pIcon1 from '../../images/background/Awards.jpg';
import Navbar from '../../components/Navbar/Navbar';
import VideoArea from '../../components/VideoArea/VideoArea';
import OurPartner from '../../components/OurPartner';
import OurPartner2 from '../../components/OurPartner2';
import OurPartner3 from '../../components/OurPartner3';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import Scrollbar from '../../components/scrollbar/scrollbar';

const HomePage = () => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Our Partners';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Forttuna Global Excellence Awards invites industry leaders to celebrate exceptional achievements across various industries. Sign up today!" />
            <meta property="og:title" content="Forttuna Global Excellence Awards | Our Partners" />
            <meta property="og:description" content="Forttuna Global Excellence Awards invites industry leaders to celebrate exceptional achievements across various industries. Sign up today!" />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1} />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar />
            <section className="page-title banner-partner2023">
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">&nbsp;</div>
                            <h2 className="page-title_heading"> &nbsp;</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Our Partner</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <section className="" style={{margin: '45px'}}>
                <div className="auto-container">
                    <div className='row'>
                       
                        <div className="col-lg-12">
                            <div class="business-one_button">
                                <a class="theme-btn btn-style-one" href="/contacts/">
                                    <span class="btn-wrap"><span class="text-one">Become A Partner</span><span class="text-two">Become A Partner</span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <OurPartner />
            {/* <OurPartner2 /> */}
            <OurPartner3 />
            {/* <VideoArea /> */}
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default HomePage;