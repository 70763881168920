import timg from '../images/resource/Randeer-Sood.jpg'
import timg1 from '../images/resource/Bu-abdullahh.jpg'
import timg2 from '../images/resource/Gaurangadas.jpg'
import timg3 from '../images/resource/Eng.-Ahmed-Al-Hosani-old.jpg'
import timg4 from '../images/resource/moran-cerf.webp'
import timg5 from '../images/resource/SAndra-Old.jpg'
import timg13 from '../images/resource/Manoj-Mohta.jpg'
import timg6 from '../images/resource/Subramanyam-Yadavalli.webp'

import tsimg from '../images/resource/Randeer-Sood.jpg'
import tsimg1 from '../images/resource/Bu-abdullahh.jpg'
import tsimg2 from '../images/resource/Gaurangadas.jpg'
import tsimg3 from '../images/resource/Eng.-Ahmed-Al-Hosani-old.jpg'
import tsimg4 from '../images/resource/moran-cerf.webp'
import tsimg5 from '../images/resource/SAndra-Old.jpg'
import tsimg6 from '../images/resource/Subramanyam-Yadavalli.webp'
import tsimg13 from '../images/resource/Manoj-Mohta.jpg'



const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Padmashri Dr. Randhir Sud',
      slug: 'https://www.linkedin.com/in/dr-randhir-sud-884659a3/',
      title: "Chairman, Institute of Digestive and Hepatobiliary Sciences, Medanta the Medicity, NCR, India",
   },
   // {
   //    Id: '2',
   //    tImg: timg1,
   //    tsImg: tsimg1,
   //    name: 'Dr. Bu Abdullah',
   //    slug: "https://www.linkedin.com/in/yaqoub-mossa-mohamed-9b7465334/",
   //    title: "Chairman - Bu Abdullah Investment Group, UAE",
   // },
   {
      Id: '3',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Gauranga Das',
      slug: 'javascript:void(0)',
      title: 'Spiritual Leader, Sustainability Advocate, and Social Innovator',
   },
   {
      Id: '4',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Eng. Ahmed Al Hosani',
      slug: "https://www.linkedin.com/in/singer-ahmed-alhosani-8a329a231/",
      title: "UAE's First Classical Singer, Emirati Musicians' Association",
   },
   {
      Id: '5',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr. Moran Cerf',
      slug: 'https://linkedin.com/in/morancerf',
      title: 'Professor of Neuroscience & Business, Columbia University, USA',
   },
   {
      Id: '6',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Prof. Dr. Sandra Matz',
      slug: 'https://linkedin.com/in/sandra-matz-6824742b',
      title: 'Professor, Columbia Business School, USA',
   },
   {
      Id: '7',
      Id: '67',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Subramanyam Yadavalli',
      slug: 'https://linkedin.com/in/subramanyam-yadavalli-6ba30059',
      title: 'CEO, Apollo Hospitals, AP & International, India',
   },

]

export default Teams;