import React from 'react'
import { Link } from 'react-router-dom'
// import SpecialInvitees from '../../api/keynoteh'
import timg1 from '../../images/resource/Moran-cerf-key.jpg'
import timg2 from '../../images/resource/Sandra-key.jpg'
import shape2 from '../../images/background/pattern-6.png'

const Speakers = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="speakers-one speakers-onn" id="speakers">
            {/* <div className="speakers-one_pattern" style={{ backgroundImage: `url(${shape1})` }}></div> */}
            <div className="speakers-one_pattern-two" style={{ backgroundImage: `url(${shape2})` }}></div>
            <div className="auto-container" style={{marginTop: '120px'}}>
                <div className="sec-title title-anim centered">
                    {/* <div className="sec-title_title">Our Speakers</div> */}
                    {/* <h2 className="sec-title_heading">Special Invitees</h2> */}
                </div>
                <div className="row clearfix" style={{ justifyContent: 'center' }}>
                    <section className="team-single-section">
                        <div className="">
                            <div className="auto-container">
                                <div className="row clearfix">
                                    <div className="speaker-detail_author-column col-lg-4 col-md-6 col-sm-12">
                                        <div className="speaker-detail_author-inner">
                                            {/* <div className="speaker-detail_author-pattern" style={{ backgroundImage: `url(${bg})` }}></div> */}
                                            <div className="speaker-detail_author-image">
                                                <img src={timg1} alt="" />
                                                <div className="speaker-detail_social-list">
                                                    <a target='_blank' href="https://www.linkedin.com/in/morancerf" className="twitter fa fa-linkedin"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-column col-lg-8 col-md-12 col-sm-12">
                                        <h2 className="speaker-detail_subtitle">Dr. Moran Cerf</h2>
                                        <h5><strong style={{color: '#fff'}}>Professor of Neuroscience & Business, Columbia University, USA</strong></h5>
                                        <p style={{color: '#fff'}}>
                                            <b>Dr. Moran Cerf,</b> is a distinguished Professor of Neuroscience and Business at Columbia University, as well as the Alfred P. Sloan Professor of Screenwriting at the Academy of Motion Picture Arts and Sciences.
                                            <br/>
                                            In his recent work, Dr. Cerf has empowered global leaders by helping them apply key lessons from decision science and neuroscience to make critical choices such as the Nuclear Launch Protocols.
                                            <br/>
                                            Prior to this, his groundbreaking research involved studying patients implanted with neural devices during brain surgery, exploring decisions and even dreams paving the way for some of today’s most exciting advances in neuroscience, including brain-machine interfaces.
                                        </p>
                                        <div style={{marginTop: '20px'}}>
                                            <a href="https://www.youtube.com/watch?v=q72LMnPX_bY" target='_blank' className='keynote'>Watch Here</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> 
                </div>
                <div className="row clearfix" style={{ justifyContent: 'center' }}>
                    <section className="team-single-section">
                        <div className="speaker-detail">
                            <div className="auto-container">
                                <div className="row clearfix">
                                    <div className="speaker-detail_author-column col-lg-4 col-md-6 col-sm-12">
                                        <div className="speaker-detail_author-inner">
                                            {/* <div className="speaker-detail_author-pattern" style={{ backgroundImage: `url(${bg})` }}></div> */}
                                            <div className="speaker-detail_author-image">
                                                <img src={timg2} alt="" />
                                                <div className="speaker-detail_social-list">
                                                    <a target='_blank' href="https://linkedin.com/in/sandra-matz-6824742b" className="twitter fa fa-linkedin"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-column col-lg-8 col-md-12 col-sm-12">
                                        <h2 className="speaker-detail_subtitle">Prof. Dr. Sandra Matz</h2>
                                        <h5><strong style={{color: '#fff'}}>Professor, Columbia Business School, USA</strong></h5>
                                        <p style={{color: '#fff'}}>
                                            <b>Prof. Dr. Sandra Matz</b> is the David W. Zalaznick Professor of Business at Columbia Business School and the author of Mindmasters.
                                            <br/>
                                            Over the last ten years Prof. Dr. Matz has published more than fifty academic papers in the world’s leading peer-reviewed journals, and her work has frequently been covered by many major news outlets, including the Economist, the New York Times, the Wall Street Journal, and Forbes.
                                        </p>
                                        <div style={{marginTop: '20px'}}>
                                            <a href="https://www.youtube.com/watch?v=iVAwA3h7n4k" target='_blank' className='keynote'>Watch Here</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> 
                </div>
            </div>
        </section>
    )
}

export default Speakers;