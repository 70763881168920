import timg from '../images/resource/manjulangani.webp'
import timg0 from '../images/resource/Subramanyam-Yadavalli.webp'
import timg3 from '../images/resource/bimal-chajjar.webp'
import timg4 from '../images/resource/Amarjit-Singh.jpg'
import timg5 from '../images/resource/Dr.-Geetika-Gupta.jpg'
import timg6 from '../images/resource/Dr.-Nabhit-Kapur.jpg'
import timg7 from '../images/resource/Dr.-Burzin-Khan.jpg'

import tsimg from '../images/resource/manjulangani.webp'
import tsimg0 from '../images/resource/Subramanyam-Yadavalli.webp'
import tsimg3 from '../images/resource/bimal-chajjar.webp'
import tsimg4 from '../images/resource/Amarjit-Singh.jpg'
import tsimg5 from '../images/resource/Dr.-Geetika-Gupta.jpg'
import tsimg6 from '../images/resource/Dr.-Nabhit-Kapur.jpg'
import tsimg7 from '../images/resource/Dr.-Burzin-Khan.jpg'
// import tsimg8 from '../images/resource/team-16.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Padmashri Dr. Manjula Anagani',
      slug: 'https://www.linkedin.com/in/dr-manjula-anagani-a5283718/',
      title: "Clinical Director, Women and Child Institute, Care Hospital, India",
   },
   {
      Id: '2',
      tImg: timg0,
      tsImg: tsimg0,
      name: 'Subramanyam Yadavalli',
      slug: 'https://www.linkedin.com/in/subramanyam-yadavalli-6ba30059/',
      title: 'CEO, Apollo Hospitals, India',
   },
   {
      Id: '3',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Dr. Bimal Chhajer',
      slug: 'https://www.linkedin.com/in/bimal-chhajer-24a4aa29/',
      title: "Cardiologist, Founder, Saaol Heart Center, India",
   },
   {
      Id: '4',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr.(Brig) Amarjit Singh',
      slug: 'https://www.linkedin.com/in/dr-brig-amarjit-singh-50b1458/',
      title: 'CEO & Principal Director, Dr. D. Y. Patil Medical College Hospital & Research Centre, India',
   },
   {
      Id: '5',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Dr. Geetika Gupta',
      slug: 'https://www.linkedin.com/in/drgeetikamittalgupta',
      title: 'Founder & Medical Director - ISAAC Luxe, India',
   },
   {
      Id: '6',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Dr. Nabhit Kapur',
      slug: 'https://www.linkedin.com/in/drnabhitkapur',
      title: 'President World Leaders for Mental Health, India',
   },
   {
      Id: '7',
      tImg: timg7,
      tsImg: tsimg7,
      name: 'Dr. Burzin Khan',
      slug: 'https://www.linkedin.com/in/opusdentalspecialities/',
      title: 'Proprietor, OPUS Dental Specialities, India',
   },
   // {
   //    Id: '6',
   //    tImg: timg5,
   //    tsImg: tsimg5,
   //    name: 'Dr. Pratit Samdani',
   //    slug: 'Dr. Pratit Samdani',
   //    title: "Oncologist, Physician & Intensivist, India",
   // },

]

export default Teams;