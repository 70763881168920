import React from "react";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

// Import images
import pImg1 from '../../images/gallery/1.jpg';
import pImg2 from '../../images/gallery/2.jpg';
import pImg3 from '../../images/gallery/3.jpg';
import pImg4 from '../../images/gallery/4.jpg';
import pImg5 from '../../images/gallery/5.jpg';
import pImg6 from '../../images/gallery/6.jpg';
import pImg7 from '../../images/gallery/7.jpg';
import pImg8 from '../../images/gallery/8.jpg';
import pImg9 from '../../images/gallery/9.jpg';
import pImg10 from '../../images/gallery/10.jpg';
import pImg11 from '../../images/gallery/11.jpg';
import pImg12 from '../../images/gallery/12.jpg';
import pImg13 from '../../images/gallery/13.jpg';
import pImg14 from '../../images/gallery/14.jpg';
import pImg15 from '../../images/gallery/15.jpg';
import pImg16 from '../../images/gallery/16.jpg';
import pImg17 from '../../images/gallery/17.jpg';
import pImg18 from '../../images/gallery/18.jpg';
import pImg19 from '../../images/gallery/19.jpg';
import pImg20 from '../../images/gallery/20.jpg';
import pImg21 from '../../images/gallery/21.jpg';
import pImg22 from '../../images/gallery/22.jpg';
import pImg23 from '../../images/gallery/23.jpg';
import pImg24 from '../../images/gallery/24.jpg';
import pImg25 from '../../images/gallery/25.jpg';
import pImg26 from '../../images/gallery/26.jpg';
import pImg27 from '../../images/gallery/27.jpg';
import pImg28 from '../../images/gallery/28.jpg';
import pImg29 from '../../images/gallery/29.jpg';
import pImg30 from '../../images/gallery/30.jpg';
import pImg31 from '../../images/gallery/31.jpg';
import pImg32 from '../../images/gallery/32.jpg';
import pImg33 from '../../images/gallery/33.jpg';
import pImg34 from '../../images/gallery/34.jpg';
import pImg35 from '../../images/gallery/35.jpg';
import pImg36 from '../../images/gallery/36.jpg';
import pImg37 from '../../images/gallery/37.jpg';
import pImg38 from '../../images/gallery/38.jpg';
import pImg39 from '../../images/gallery/39.jpg';
import pImg40 from '../../images/gallery/40.jpg';
import pImg41 from '../../images/gallery/41.jpg';
import pImg42 from '../../images/gallery/42.jpg';
import pImg43 from '../../images/gallery/43.jpg';
import pImg44 from '../../images/gallery/44.jpg';
import pImg45 from '../../images/gallery/45.jpg';
import pImg46 from '../../images/gallery/46.jpg';
import pImg47 from '../../images/gallery/47.jpg';
import pImg48 from '../../images/gallery/48.jpg';
import pImg49 from '../../images/gallery/49.jpg';
import pImg50 from '../../images/gallery/50.jpg';
import pImg51 from '../../images/gallery/51.jpg';
import pImg52 from '../../images/gallery/52.jpg';
import pImg53 from '../../images/gallery/53.jpg';
import pImg54 from '../../images/gallery/54.jpg';
import pImg55 from '../../images/gallery/55.jpg';
import pImg56 from '../../images/gallery/56.jpg';
import pImg57 from '../../images/gallery/57.jpg';
import pImg58 from '../../images/gallery/58.jpg';
import pImg59 from '../../images/gallery/59.jpg';
import pImg60 from '../../images/gallery/60.jpg';
import pImg61 from '../../images/gallery/61.jpg';

// Add all your imports...

// Array of images
const 
images = [
    pImg1,
    pImg2,
    pImg3,
    pImg4,
    pImg5,
    pImg6,
    pImg7,
    pImg8,
    pImg9,
    pImg10,
    pImg11,
    pImg12,
    pImg13,
    pImg14,
    pImg15,
    pImg16,
    pImg17,
    pImg18,
    pImg19,
    pImg20,
    pImg21,
    pImg22,
    pImg23,
    pImg24,
    pImg25,
    pImg26,
    pImg27,
    pImg28,
    pImg29,
    pImg30,
    pImg31,
    pImg32,
    pImg33,
    pImg34,
    pImg35,
    pImg36,
    pImg37,
    pImg38,
    pImg39,
    pImg40,
    pImg41,
    pImg42,
    pImg43,
    pImg44,
    pImg45,
    pImg46,
    pImg47,
    pImg48,
    pImg49,
    pImg50,
    pImg51,
    pImg52,
    pImg53,
    pImg54,
    pImg55,
    pImg56,
    pImg57,
    pImg58,
    pImg59,
    pImg60,
    pImg61,
];

// Convert images to slides
const slides = images.map((image) => ({ src: image }));

const GallerySection3 = () => {
    const [open, setOpen] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(0);

    // Handle image click
    const handleImageClick = (index) => {
        setCurrentIndex(index); // Set the index of the clicked image
        setOpen(true); // Open the lightbox
    };

    return (
        <section className="gallery-three">
            <div className="auto-container">
                <div className="row clearfix">
                    {images.map((item, index) => (
                        <div
                            className="gallery-one_block col-lg-4 col-md-4 col-sm-6"
                            key={index}
                        >
                            <div className="gallery-one_block-inner">
                                <div className="gallery-one_block-image">
                                    <img src={item} alt={`Gallery Image ${index + 1}`} />
                                    {/* <div className="gallery-one_block-overlay">
                                        <span className="gallery-one_block-icon flaticon-plus-symbol"></span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={slides} // Use the slides array
                currentIndex={currentIndex} // Show the clicked image
                plugins={[Zoom]}
            />
        </section>
    );
};

export default GallerySection3;

