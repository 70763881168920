import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom'
import pIcon1 from '../../images/background/Health-2023.jpg';
import pIcon2 from '../../images/background/Why-Attend-Forttuna-Awards.jpg';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Juryindia from '../../components/Juryindia/Team';
import Features from '../../components/Features';
import HealthIndia from '../../components/HealthIndia/HealthIndia';
import VideoArea from '../../components/VideoAreah/VideoArea';
import pIcon4 from '../../images/background/FFTB-Banner-health.png';
import PartnerSection from '../../components/PartnerSection';
import Features2 from '../../components/FeaturesB';
import pIcon3 from '../../images/background/India---Awardee-Deliverables-Health-Banner.jpg';
import VenueSection from '../../components/VenueSection/VenueSection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import pIcon5 from '../../images/background/India---Key-Highlights-of-the-Gala-Health-Banner.jpg';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const AboutPage = (props) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | India Edition | Health & Wellness Leaders';
    }, []); // The empty array ensures this runs only once when the component moun
    return (
        <>
        <Helmet>
            <meta name="description" content="Forttuna Global Excellence Awards | India Edition | Health & Wellness Leaders" />
            <meta property="og:title" content="Forttuna Global Excellence Awards | India Edition | Health & Wellness Leaders" />
            <meta property="og:description" content="The Forttuna Global Excellence Awards understands that health encompasses the most crucial facet of our life, be it physical or mental." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1} />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar />
            <section className="page-title banner-indiah">
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Health & Wellness Leaders Edition</div>
                            <h2 className="page-title_heading">Health & Wellness Leaders Edition</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Health</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <HealthIndia />
            <section  style={{marginBottom:'90px', marginTop:'90px'}}>
                {/* <div className="feature-one_pattern" style={{ backgroundImage: `url(${sIcon})` }}></div>
                <div className="feature-one_pattern-two" style={{ backgroundImage: `url(${sIcon2})` }}></div>
                <div className="feature-one_pattern-three" style={{ backgroundImage: `url(${sIcon3})` }}></div> */}
                <div>
                    <img src={pIcon3} alt="Description of the image" />
                </div>
            </section>
            {/* <Features2 /> */}
           
            <section  style={{marginBottom:'90px', marginTop:'90px'}}>
                {/* <div className="feature-one_pattern" style={{ backgroundImage: `url(${sIcon})` }}></div>
                <div className="feature-one_pattern-two" style={{ backgroundImage: `url(${sIcon2})` }}></div>
                <div className="feature-one_pattern-three" style={{ backgroundImage: `url(${sIcon3})` }}></div> */}
                <div>
                    <img src={pIcon2} alt="Description of the image" />
                </div>
            </section>
            <section  style={{marginBottom:'90px', marginTop:'90px'}}>
                {/* <div className="feature-one_pattern" style={{ backgroundImage: `url(${sIcon})` }}></div>
                <div className="feature-one_pattern-two" style={{ backgroundImage: `url(${sIcon2})` }}></div>
                <div className="feature-one_pattern-three" style={{ backgroundImage: `url(${sIcon3})` }}></div> */}
                <div className="sec-title title-anim centered">
                        {/* <div className="sec-title_title">Our Speakers</div> */}
                        <h2 className="sec-title_heading">Who Can Participate?</h2>
                    </div>
                <div>
                    <img src={pIcon4} alt="Description of the image" />
                </div>
            </section>
            <section  style={{marginBottom:'90px', marginTop:'90px'}}>
                {/* <div className="feature-one_pattern" style={{ backgroundImage: `url(${sIcon})` }}></div>
                <div className="feature-one_pattern-two" style={{ backgroundImage: `url(${sIcon2})` }}></div>
                <div className="feature-one_pattern-three" style={{ backgroundImage: `url(${sIcon3})` }}></div> */}
                <div className="sec-title title-anim centered">
                        {/* <div className="sec-title_title">Our Speakers</div> */}
                        {/* <h2 className="sec-title_heading">Who Can Participate?</h2> */}
                    </div>
                <div>
                    <img src={pIcon5} alt="Description of the image" />
                </div>
            </section>
            {/* <Features /> */}
            <Juryindia />
            <VideoArea />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default AboutPage;
