import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import Homepage from '../HomePage/HomePage'
// import HomePage2 from '../HomePage2/HomePage2';
// import HomePage3 from '../HomePage3/HomePage3';
// import HomePageOnePage from '../HomePageOnePage/HomePageOnePage';
// import HomePageOnePage2 from '../HomePageOnePage2/HomePageOnePage2';
// import HomePageOnePage3 from '../HomePageOnePage3/HomePageOnePage3';
import OurAim from '../OurAim/OurAim';
// import AboutPage from '../AboutPage/AboutPage';
import WhoWeAre from '../WhoWeAre/WhoWeAre';
import TheTeam from '../TheTeam/TheTeam';
import AllJury from '../AllJury/TheTeam';
import AllJuryIndia from '../AllJuryIndia/AllJuryIndia';
import AwardCategories from '../AwardCategories/AwardCategories';
import AwardCategoriesIndia from '../AwardCategoriesIndia/AwardCategoriesIndia';
import ParticipationGuidelines from '../ParticipationGuidelines/ParticipationGuidelines';
import ParticipationGuidelinesIndia2025 from '../ParticipationGuidelinesIndia2025/ParticipationGuidelinesIndia2025';
import HealthWellnessEdition from '../HealthWellnessEdition2025/HealthWellnessEdition';
import GreenEdition from '../GreenEdition2025/GreenEdition2025';
import HealthWellnessEditionIndia2025 from '../HealthWellnessEditionIndia-2025/HealthWellnessEditionIndia-2025';
import Health2023 from '../Health2023/Health2023';
import BusinessLeadersEditionglobal2024 from '../BusinessLeadersEdition/BusinessLeadersEdition';
import BusinessLeadersEdition from '../BusinessLeadersEdition2025/BusinessLeadersEdition';
import BusinessLeadersEditionIndia2025 from '../BusinessLeadersEditionIndia-2025/BusinessLeadersEditionIndia-2025';
import SpecialInviteesglobal2024 from '../SpecialInvitees/SpecialInvitees';
import SpecialInvitees from '../SpecialInvitees2025/SpecialInvitees';
import SpecialInviteesIndia from '../SpecialInviteesIndia/SpecialInviteesIndia';
import SpecialGuests from '../SpecialGueste/SpecialGueste';
import SpecialGuests24 from '../SpecialGueste/SpecialGueste24';
import SpecialGuests242 from '../SpecialGueste/SpecialGueste242';
import KeynoteSpeakers2024b from '../KeynoteSpeakers/KeynoteSpeakers2024b';
import KeynoteSpeakers2024h from '../KeynoteSpeakers/KeynoteSpeakers2024h';
import PastWinner from '../PastWinner/PastWinner';
import PastWinnerb from '../PastWinner/PastWinnerbusniess';
import PastWinnerh from '../PastWinner/PastWinnerhealth';
import OurPartner from '../OurPartner/OurPartner';
import OurPartnerbusiness from '../OurPartner/OurPartner-business.js';
import OurPartnerhealth from '../OurPartner/OurPartner-health.js';
import News from '../News/News';
import TheFfttbShow from '../TheFfttbShow/TheFfttbShow';
// import SpeakerSinglePage from '../SpeakerSinglePage/SpeakerSinglePage';
import AdditiHanda from '../AdditiHanda/AdditiHanda';
import RaulHanda from '../RaulHanda/RaulHanda';
// import EventSinglePage from '../EventSinglePage/EventSinglePage';
import Awards1 from '../Awards1/Awards1';
import Awards2 from '../Awards2/Awards2';
import Awards3 from '../Awards3/Awards3';
import Awards4 from '../Awards4/Awards4';
import Awards5 from '../Awards5/Awards5';
import Awards6 from '../Awards6/Awards6';
import Awards7 from '../Awards7/Awards7';
import Awards8 from '../Awards8/Awards8';
import Awards9 from '../Awards9/Awards9';
import Awards10 from '../Awards10/Awards10';
import Awards11 from '../Awards11/Awards11';
import Awards12 from '../Awards12/Awards12';
import Awards13 from '../Awards13/Awards13';
import Awards14 from '../Awards14/Awards14';
import Awards15 from '../Awards15/Awards15';
import Awards16 from '../Awards16/Awards16';
import Awards17 from '../Awards17/Awards17';
import Awards18 from '../Awards18/Awards18';
import Awards19 from '../Awards19/Awards19';
import Awards20 from '../Awards20/Awards20';
import Awards21 from '../Awards21/Awards21';
import Awards22 from '../Awards22/Awards22';
import Awards23 from '../Awards23/Awards23';
import Awards24 from '../Awards24/Awards24';
import Awards25 from '../Awards25/Awards25';
import Awards26 from '../Awards26/Awards26';
import Awards27 from '../Awards27/Awards27';
import Awards28 from '../Awards28/Awards28';
import Awards29 from '../Awards29/Awards29';
import Awards30 from '../Awards30/Awards30';
import Awards31 from '../Awards31/Awards31';
import Awards32 from '../Awards32/Awards32';
import Awards33 from '../Awards33/Awards33';
import Awards34 from '../Awards34/Awards34';
import Awards35 from '../Awards35/Awards35';
import Awards36 from '../Awards36/Awards36';
import Awards37 from '../Awards37/Awards37';
import Awards38 from '../Awards38/Awards38';
import Awards39 from '../Awards39/Awards39';
import Awards40 from '../Awards40/Awards40';
import Awards41 from '../Awards41/Awards41';
import Awards42 from '../Awards42/Awards42';
import Awards43 from '../Awards43/Awards43';
import Awards44 from '../Awards44/Awards44';
import Awards45 from '../Awards45/Awards45';
import Awards46 from '../Awards46/Awards46';
import Awards47 from '../Awards47/Awards47';
// import BookTicket from '../BookTicket/BookTicket';

// import PricingPage from '../PricingPage/PricingPage';
import BlogPage from '../BlogPage/BlogPage'
// import BlogDetails from '../BlogDetails/BlogDetails';
import BlogDetails2 from '../BlogDetails2/UpcomingHealthEvents';
import BlogDetails3 from '../BlogDetails3/FinancialServicesAwards';
import BlogDetails4 from '../BlogDetails4/HealthcareAward';
import BlogDetails5 from '../BlogDetails5/DoctorAward';
import BlogDetails6 from '../BlogDetails6/GlobalEntertainmentAwards';
import BlogDetails7 from '../BlogDetails7/BlogDetails7';
import BlogDetails8 from '../BlogDetails8/BlogDetails8';
import BlogDetails9 from '../BlogDetails9/BlogDetails9';
import BlogDetails10 from '../BlogDetails10/BlogDetails10';
import BlogDetails11 from '../BlogDetails11/BlogDetails11';
import BlogDetails12 from '../BlogDetails12/BlogDetails12';
import BlogDetails13 from '../BlogDetails13/BlogDetails13';
import BlogDetails14 from '../BlogDetails14/BlogDetails14';
import BlogDetails15 from '../BlogDetails15/BlogDetails15';
import BlogDetails16 from '../BlogDetails16/BlogDetails16';
import BlogDetails17 from '../BlogDetails17/BlogDetails17';
import BlogDetails18 from '../BlogDetails18/BlogDetails18';
import BlogDetails19 from '../BlogDetails19/BlogDetails19';
import BlogDetails20 from '../BlogDetails20/BlogDetails20';
import BlogDetails21 from '../BlogDetails21/BlogDetails21';
import BlogDetails22 from '../BlogDetails22/BlogDetails22';
import BlogDetails23 from '../BlogDetails23/BlogDetails23';
import BlogDetails24 from '../BlogDetails24/BlogDetails24';
import BlogDetails25 from '../BlogDetails25/BlogDetails25';
import BlogDetails26 from '../BlogDetails26/BlogDetails26';
import BlogDetails27 from '../BlogDetails27/BlogDetails27';
import BlogDetails28 from '../BlogDetails28/BlogDetails28';
import BlogDetails29 from '../BlogDetails29/BlogDetails29';
import BlogDetails30 from '../BlogDetails30/BlogDetails30';
import BlogDetails31 from '../BlogDetails31/BlogDetails31';
import BlogDetails32 from '../BlogDetails32/BlogDetails32';
import BlogDetails33 from '../BlogDetails33/BlogDetails33';
import BlogDetails34 from '../BlogDetails34/BlogDetails34';
import BlogDetails35 from '../BlogDetails35/BlogDetails35';
import BlogDetails36 from '../BlogDetails36/BlogDetails36';
import BlogDetails37 from '../BlogDetails37/BlogDetails37';
import BlogDetails38 from '../BlogDetails38/BlogDetails38';
import BlogDetails39 from '../BlogDetails39/BlogDetails39';
import BlogDetails40 from '../BlogDetails40/BlogDetails40';
import BlogDetails41 from '../BlogDetails41/BlogDetails41';
import BlogDetails42 from '../BlogDetails42/BlogDetails42';
import BlogDetails43 from '../BlogDetails43/BlogDetails43';
import BlogDetails44 from '../BlogDetails44/BlogDetails44';
import BlogDetails45 from '../BlogDetails45/BlogDetails45';
import BlogDetails46 from '../BlogDetails46/BlogDetails46';
import BlogDetails47 from '../BlogDetails47/BlogDetails47';
import BlogDetails48 from '../BlogDetails48/BlogDetails48';
import BlogDetails49 from '../BlogDetails49/BlogDetails49';
// import BlogPageLeft from '../BlogPageLeft/BlogPageLeft'
// import BlogListPage from '../BlogListPage/BlogListPage';
import TermPage from '../TermPage/TermPage';
import PrivacyPage from '../PrivacyPage/PrivacyPage';
import CookiePolicy from '../CookiePolicy/CookiePolicy';
import ErrorPage from '../ErrorPage/ErrorPage';
// import TestimonialPage from '../TestimonialPage/TestimonialPage';
// import AuthPage from '../AuthPage/AuthPage';
import FaqPage from '../FaqPage';
import PaymentFailed from '../PaymentFailed/PaymentFailed';
import Thankyou from '../Thankyou/Thankyou';
import ThankyouTicket from '../ThankyouTicket/Thankyou';
import SchedulePage from '../SchedulePage/SchedulePage';
import SchedulePagehealth from '../SchedulePage/SchedulePagehealth';
import SchedulePagehealthindia from '../SchedulePage/SchedulePagehealthindia';
import SchedulePagebusniess from '../SchedulePage/SchedulePagebusniess';
import SchedulePagebusniessindia from '../SchedulePage/SchedulePagebusniessindia';
import SchedulePageGreen from '../SchedulePage/SchedulePagegreen.js';
import GalleryPage from '../GalleryPage/GalleryPage';
import GalleryPage2024b from '../Gallery2024/gallerybusniess';
import GalleryPage2024h from '../Gallery2024/galleryhealth';
import Fsep from '../FSEP/FSEP';
// import ComingSoon from '../ComingSoon/ComingSoon';
// import SpeakerPage from '../SpeakerPage/SpeakerPage';
import EventsPage from '../EventsPage/EventsPage';
// import ContactPage from '../ContactPage/ContactPage';
import ContactPage2 from '../ContactPage2/ContactPage2';
// import ContactPage3 from '../ContactPage3/ContactPage3';
// import Participate from '../Participate';


const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/" element={<Homepage />} />
          <Route path="who-we-are/" element={<WhoWeAre />} />
          <Route path="/team/" element={<TheTeam />} />
          <Route path="our-aim/" element={<OurAim />} />
          <Route path="health-wellness-edition/" element={<HealthWellnessEdition />} />
          <Route path="green-leaders-edition/" element={<GreenEdition />} />
          <Route path="health-wellness-edition-india/" element={<HealthWellnessEditionIndia2025 />} />
          <Route path="business-leaders-edition/" element={<BusinessLeadersEdition/>} />
          <Route path="business-leaders-edition-2024/" element={<BusinessLeadersEditionglobal2024/>} />
          <Route path="business-leaders-edition-india/" element={<BusinessLeadersEditionIndia2025/>} />
          <Route path="special-invitees/" element={<SpecialInvitees/>} />
          <Route path="special-invitees-2024/" element={<SpecialInviteesglobal2024/>} />
          <Route path="special-invitees-india/" element={<SpecialInviteesIndia/>} />
          <Route path="the-jury/" element={<AllJury/>} />
          <Route path="the-jury-india/" element={<AllJuryIndia/>} />
          <Route path="/participation-guidelines/" element={<ParticipationGuidelines/>} />
          <Route path="/participation-guidelines-india/" element={<ParticipationGuidelinesIndia2025/>} />
          <Route path="health-wellness-edition-2023/" element={<Health2023/>} />
          <Route path="health-wellness-edition-2024/" element={<HealthWellnessEdition/>} />
          <Route path="special-guests/" element={<SpecialGuests/>} />
          <Route path="special-guests-global-business-2024/" element={<SpecialGuests24/>} />
          <Route path="special-guests-global-health-2024/" element={<SpecialGuests242/>} />
          <Route path="keynote-business-2024/" element={<KeynoteSpeakers2024b/>} />
          <Route path="keynote-health-2024/" element={<KeynoteSpeakers2024h/>} />
          <Route path="past-winners/" element={<PastWinner/>} />
          <Route path="past-winners-business-2024/" element={<PastWinnerb/>} />
          <Route path="past-winners-health-2024/" element={<PastWinnerh/>} />
          <Route path="/our-partners/" element={<OurPartner/>} />
          <Route path="/our-partners-business-2024/" element={<OurPartnerbusiness/>} />
          <Route path="/our-partners-health-2024/" element={<OurPartnerhealth/>} />
          <Route path="/award-categories/" element={<AwardCategories />} />
          <Route path="/award-categories-india/" element={<AwardCategoriesIndia />} />
          <Route path="/newsroom/" element={<News />} />
          <Route path="/the-fftb-show/" element={<TheFfttbShow />} />
          <Route path="awards/" element={<EventsPage />} />
          {/* <Route path="event-details/:slug" element={<EventSinglePage />} /> */}
          <Route path="/faq/" element={<FaqPage />} />
          {/* <Route path='/price/' element={<PricingPage />} /> */}
          {/* <Route path='participate' element={<Participate />} /> */}
          <Route path='schedule/' element={<SchedulePage />} />
          <Route path='schedule-health-wellness-global/' element={<SchedulePagehealth />} />
          <Route path='schedule-health-wellness-india/' element={<SchedulePagehealthindia />} />
          <Route path='schedule-business-leaders-global/' element={<SchedulePagebusniess />} />
          <Route path='schedule-green-leaders/' element={<SchedulePageGreen />} />
          <Route path='schedule-business-leaders-india/' element={<SchedulePagebusniessindia />} />
          <Route path='memories-2023/' element={<GalleryPage />} />
          <Route path='memories-business-2024/' element={<GalleryPage2024b />} />
          <Route path='memories-health-2024/' element={<GalleryPage2024h />} />
          <Route path='startup/' element={<Fsep />} />
          {/* <Route path="testimonial/" element={<TestimonialPage />} /> */}
          {/* <Route path="coming-soon/" element={<ComingSoon />} /> */}
          <Route path="/terms-of-use/" element={<TermPage />} />
          <Route path="/privacy-policy/" element={<PrivacyPage />} />
          <Route path="/cookie-policy/" element={<CookiePolicy />} />
          {/* <Route path="speaker/" element={<SpeakerPage />} /> */}
          {/* <Route path="speaker-details/:slug" element={<SpeakerSinglePage />} /> */}
          <Route path="team/aditii-handa/" element={<AdditiHanda />} />
          <Route path="team/raul-handa/" element={<RaulHanda />} />
          {/* <Route path='register/' element={<AuthPage />} /> */}
          <Route path='blog/' element={<BlogPage />} />
          {/* <Route path='blog-left-sidebar' element={<BlogPageLeft />} />
          <Route path='blog-classNameic' element={<BlogListPage />} /> */}
          <Route path='/:slug' element={<ErrorPage />} />
          <Route path='/speaker-details/:slug' element={<ErrorPage />} />
          <Route path='/upcoming-health-events/' element={<BlogDetails2 />} />
          <Route path='/financial-services-awards/' element={<BlogDetails3 />} />
          <Route path='/healthcare-award/' element={<BlogDetails4 />} />
          <Route path='/doctor-award/' element={<BlogDetails5 />} />
          <Route path='/global-entertainment-awards/' element={<BlogDetails6 />} />
          <Route path='/women-leaders-in-healthcare/' element={<BlogDetails7 />} />
          <Route path='/modern-healthcare-awards/' element={<BlogDetails8 />} />
          <Route path='/aviation-awards-2024/' element={<BlogDetails9 />} />
          <Route path='/health-marketing-awards/' element={<BlogDetails10 />} />
          <Route path='/leadership-in-healthcare/' element={<BlogDetails11 />} />
          <Route path='/medical-awards/' element={<BlogDetails12 />} />
          <Route path='/service-awards/' element={<BlogDetails13 />} />
          <Route path='/real-estate/' element={<BlogDetails14 />} />
          <Route path='/healthcare-leadership/' element={<BlogDetails15 />} />
          <Route path='/2024-award-shows/' element={<BlogDetails16 />} />
          <Route path='/healthcare-networking-events/' element={<BlogDetails17 />} />
          <Route path='/wellness-awards/' element={<BlogDetails18 />} />
          <Route path='/healthcare-asia-awards-2024/' element={<BlogDetails19 />} />
          <Route path='/what-is-an-industry-award/' element={<BlogDetails20 />} />
          <Route path='/health-awards/' element={<BlogDetails21 />} />
          <Route path='/healthcare-events/' element={<BlogDetails22 />} />
          <Route path='/global-health-initiatives/' element={<BlogDetails23 />} />
          <Route path='/global-awards/' element={<BlogDetails24 />} />
          <Route path='/top-10-awards-in-india/' element={<BlogDetails25 />} />
          <Route path='/uk-tech-awards/' element={<BlogDetails26 />} />
          <Route path='/award-ceremony-outfits/' element={<BlogDetails27 />} />
          <Route path='/australian-small-business-champion-awards/' element={<BlogDetails28 />} />
          <Route path='/restaurant-award/' element={<BlogDetails29 />} />
          <Route path='/uk-business-award/' element={<BlogDetails30 />} />
          <Route path='/growing-business-award/' element={<BlogDetails31 />} />
          <Route path='/hospitality-award-rates/' element={<BlogDetails32 />} />
          <Route path='/restaurant-industry-award/' element={<BlogDetails33 />} />
          <Route path='/small-business-awards-2024/' element={<BlogDetails34 />} />
          <Route path='/b2b-marketing-awards/' element={<BlogDetails35 />} />
          <Route path='/corporate-recognition-awards/' element={<BlogDetails36 />} />
          <Route path='/upcoming-award-shows-2024/' element={<BlogDetails37 />} />
          <Route path='/business-excellence-awards/' element={<BlogDetails38 />} />
          <Route path='/award-shows-forttuna/' element={<BlogDetails39 />} />
          <Route path='/award-shows-2024-forttuna/' element={<BlogDetails40 />} />
          <Route path='/top-business-awards-in-india/' element={<BlogDetails41 />} />
          <Route path='/top-awards-for-businesses/' element={<BlogDetails42 />} />
          <Route path='/real-estate-industry-recognition/' element={<BlogDetails43 />} />
          <Route path='/top-global-education-awards/' element={<BlogDetails44 />} />
          <Route path='/world-sustainability-awards/' element={<BlogDetails45 />} />
          <Route path='/award-for-global-leadership/' element={<BlogDetails46 />} />
          <Route path='/top-product-awards/' element={<BlogDetails47 />} />
          <Route path='/global-recognition-awards/' element={<BlogDetails48 />} />
          <Route path='/big-innovation-awards/' element={<BlogDetails49 />} />
          <Route path='/fintech-award/' element={<Awards1 />} />
          <Route path='/aviation-awards/' element={<Awards2 />} />
          <Route path='/international-business-awards/' element={<Awards3 />} />
          <Route path='/corporate-and-financial-award/' element={<Awards4 />} />
          <Route path='/healthcare-advertising-awards/' element={<Awards5 />} />
          <Route path='/awards/health-insurance-awards/' element={<Awards6 />} />
          <Route path='/awards/wellness-awards-2024/' element={<Awards7 />} />
          <Route path='/awards/corporate-awards/' element={<Awards8 />} />
          <Route path='/awards/hr-awards/' element={<Awards9 />} />
          <Route path='/awards/business-awards/' element={<Awards10 />} />
          <Route path='/awards/marketing-awards/' element={<Awards11 />} />
          <Route path='/awards/entrepreneur-awards/' element={<Awards12 />} />
          <Route path='/awards/advertising-awards/' element={<Awards13 />} />
          <Route path='/awards/healthcare-awards/' element={<Awards14 />} />
          <Route path='/awards/real-estate-awards/' element={<Awards15 />} />
          <Route path='/awards/small-business-awards/' element={<Awards16 />} />
          <Route path='/awards/education-awards/' element={<Awards17 />} />
          <Route path='/awards/health-services-awards/' element={<Awards18 />} />
          <Route path='/awards/architecture-awards/' element={<Awards19 />} />
          <Route path='/awards/manufacturing-awards/' element={<Awards20 />} />
          <Route path='/awards/women-in-business-awards/' element={<Awards21 />} />
          <Route path='/awards/hospitality-awards/' element={<Awards22 />} />
          <Route path='/awards/health-innovation-awards/' element={<Awards23 />} />
          <Route path='/awards/technology-award/' element={<Awards24 />} />
          <Route path='/awards/health-professionals/' element={<Awards25 />} />
          <Route path='/awards/health-professionals-award/' element={<Awards26 />} />
          <Route path='/awards/technology-award/' element={<Awards27 />} />
          <Route path='/awards/sme-business-awards/' element={<Awards28 />} />
          <Route path='/awards/manufacturing-and-occupation/' element={<Awards29 />} />
          <Route path='/awards/health-support-services-award/' element={<Awards30 />} />
          <Route path='/awards/healthcare-it-leaders-awards/' element={<Awards31 />} />
          <Route path='/awards/fintech-breakthrough-awards/' element={<Awards32 />} />
          <Route path='/awards/health-awards/' element={<Awards33 />} />
          <Route path='/awards/nurse-awards/' element={<Awards34 />} />
          <Route path='/awards/banking-tech-awards/' element={<Awards35 />} />
          <Route path='/awards/business-excellence-awards/' element={<Awards36 />} />
          <Route path='/best-business-awards-in-india/' element={<Awards37 />} />
          <Route path='/top-advertising-awards/' element={<Awards38 />} />
          <Route path='/top-recognition-awards/' element={<Awards39 />} />
          <Route path='/awards-for-doctors-in-india/' element={<Awards40 />} />
          <Route path='/awards-for-entrepreneurs-in-india/' element={<Awards41 />} />
          <Route path='/us-fintech-awards/' element={<Awards42 />} />
          <Route path='/tech-awards/' element={<Awards43 />} />
          <Route path='/hr-excellence-awards/' element={<Awards44 />} />
          <Route path='/best-sustainability-awards/' element={<Awards45 />} />
          <Route path='/women-in-tech-awards/' element={<Awards46 />} />
          <Route path='/social-media-awards/' element={<Awards47 />} />
          <Route path='/payment-failure/' element={<PaymentFailed />} />
          <Route path='/thank-you-successful-payment/' element={<Thankyou />} />
          <Route path='/thank-you-ticket/' element={<ThankyouTicket />} />
          <Route path='not-found/' element={<ErrorPage />} />
          {/* <Route path='contact/' element={<ContactPage />} /> */}
          <Route path='/contacts/' element={<ContactPage2 />} />
          {/* <Route path='/book-ticket/' element={<BookTicket />} /> */}
          {/* <Route path='contact-3' element={<ContactPage3 />} /> */}
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
