import React from 'react'
import { Link } from 'react-router-dom'
import Jury2 from '../../api/jury2india'
import shape1 from '../../images/background/pattern-4.png'
import shape2 from '../../images/background/pattern-6.png'
import shape3 from '../../images/background/pattern-5.png'

const Speakers = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="speakers-one speakers-onn" id="speakers">
            {/* <div className="speakers-one_pattern" style={{ backgroundImage: `url(${shape1})` }}></div> */}
            <div className="speakers-one_pattern-two" style={{ backgroundImage: `url(${shape2})` }}></div>
            <div className="auto-container" style={{marginTop: '78px'}}>
                <div className="sec-title title-anim centered">
                    {/* <div className="sec-title_title">Our Speakers</div> */}
                    <h2 className="sec-title_heading">The Jury</h2>
                </div>
                <div className="row clearfix" style={{ justifyContent: 'center' }}>
                    {Jury2.slice(0, 25).map((team, aitem) => (
                        <div className="speaker-block_one col-lg-3 col-md-6 col-sm-12" key={aitem}>
                            <div className="speaker-block_one-inner">
                                <div className="speaker-block_one-image">
                                    <img src={team.tImg} alt="" />
                                    <div className="speaker-block_one-pattern"></div>
                                    <div className="speaker-block_one-social">
                                        <a class='sociala' href={team.slug} className="speaker-block_one-share fa fa-linkedin"></a>
                                    </div>
                                </div>
                                <div className="speaker-block_one-content">
                                    <h4 className="speaker-one_title" style={{color: "#fff"}}>{team.name}</h4>
                                    <div className="speaker-one_detail">{team.title}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="about-one_button" style={{textAlign: 'center'}}>
                        <Link to="/the-jury-india/" onClick={ClickHandler} className="theme-btn btn-style-one">
                            <span className="btn-wrap">
                                <span className="text-one">Know More</span>
                                <span className="text-two">Know More</span>
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Speakers;
