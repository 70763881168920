import React from 'react'
import { Link } from 'react-router-dom'
import PastWinner from '../../api/PastWinnerhealth'
// import shape1 from '../../images/background/pattern-20.png'

const Speakers2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="team-one" id='speakers'>
            <div className="auto-container">
                <div className="row clearfix">

                    {PastWinner.slice(0, 100).map((team, aitem) => (
                        <div className="team-block_one col-lg-4 col-md-6 col-sm-12"  key={aitem}>
                            <div className="team-block_one-inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div className="team-block_one-pattern"></div>
                                <div className="team-block_one-image">
                                    <img src={team.tsImg} alt="" />
                                    {/* <div className="team-block_one-social-list">
                                        <Link onClick={ClickHandler} to={`/speaker-details/${team.slug}`} className="facebook fa fa-facebook"></Link>
                                        <Link onClick={ClickHandler} to={`/speaker-details/${team.slug}`} className="youtube fi flaticon-youtube"></Link>
                                        <Link onClick={ClickHandler} to={`/speaker-details/${team.slug}`} className="twitter fi flaticon-instagram"></Link>
                                        <Link onClick={ClickHandler} to={`/speaker-details/${team.slug}`} className="twitter fa fa-brands fa-twitter"></Link>
                                    </div> */}
                                </div>
                                <div className="team-block_one-content">
                                    <h4 className="team-block_one-title" style={{color: '#fff'}}>{team.name}</h4>
                                    <div className="team-block_one-designation" style={{fontWeight: '800'}}>{team.com}</div>
                                    <div className="team-block_one-designation">{team.title}</div>
                                    <div className="team-block_one-designation">{team.title2}</div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
    )
}

export default Speakers2;
