import React, { Fragment, useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
// import Logo from '../../images/forttuna-logo2.png'
import Logo from '../../images/Forttuna-1.png'
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/',
    },

    {
        id: 4,
        title: '2025 Global Edition',
        // link: '/about',
        submenu: [
            {
                id: 41,
                title: 'Health & Wellness Leaders Edition',
                link: '/health-wellness-edition/',
            },
            {
                id: 42,
                title: 'Business Leaders Edition',
                link: '/business-leaders-edition'
            },
            {
                id: 43,
                title: 'Green Leaders (Sustainability) Edition',
                link: '/green-leaders-edition/'
            },
            {
                id: 44,
                title: 'Special Invitees',
                link: '/special-invitees/'
            },
            {
                id: 45,
                title: 'The Jury',
                link: '/the-jury/'
            },
            {
                id: 46,
                title: 'Participation Guidelines',
                link: '/participation-guidelines/'
            },
            {
                id: 47,
                title: 'Award Categories',
                link: '/award-categories/'
            },
        ]
    },

    {
        id: 6,
        title: '2025 India Edition',
        // link: '/speaker',
        submenu: [
            {
                id: 61,
                title: 'Health & Wellness Leaders Edition',
                link: '/health-wellness-edition-india/'
            },
            {
                id: 62,
                title: 'Business Leaders Edition',
                link: '/business-leaders-edition-india/'
            },
            {
                id: 63,
                title: 'Special Invitees',
                link: '/special-invitees-india/'
            },
            {
                id: 64,
                title: 'The Jury',
                link: '/the-jury-india/'
            },
            {
                id: 65,
                title: 'Participation Guidelines',
                link: '/participation-guidelines-india/'
            },
            {
                id: 66,
                title: 'Award Categories',
                link: '/award-categories-india/'
            },
        ]
    },

    
    {
        id: 1,
        title: 'FSEP',
        link: '/startup/',
    },

    {
        id: 9,
        title: '2024 Business Leaders Global Edition',
        link: 'javascript:void(0)',
        
        submenu: [
            
            {
                id: 71,
                title: '2024 Edition Special Invitees',
                link: '/special-guests-global-business-2024/'
            },
            {
                id: 72,
                title: '2024 Edition Partners',
                link: '/our-partners-business-2024/'
            },
            {
                id: 73,
                title: '2024 Edition Winners',
                link: '/past-winners-business-2024/'
            },
            {
                id: 74,
                title: '2024 Keynote Speakers',
                link: '/keynote-business-2024/'
            },
            {
                id: 74,
                title: 'Memories',
                link: '/memories-business-2024/'
            }
        ]
    },
    {
        id: 10,
        title: '2024 Health & Wellness Leaders Global Edition',
        link: 'javascript:void(0)',
        
        submenu: [
            
            {
                id: 71,
                title: '2024 Edition Special Invitees',
                link: '/special-guests-global-health-2024/'
            },
            {
                id: 72,
                title: '2024 Edition Partners',
                link: '/our-partners-health-2024/'
            },
            {
                id: 73,
                title: '2024 Edition Winners',
                link: '/past-winners-health-2024/'
            },
            {
                id: 74,
                title: '2024 Keynote Speakers',
                link: '/keynote-health-2024/'
            },
            {
                id: 74,
                title: 'Memories',
                link: '/memories-health-2024/'
            }
        ]
    },
    {
        id: 7,
        title: '2023 Health & Wellness Leaders Global Edition',
        link: 'javascript:void(0)',
        
        submenu: [
            
            {
                id: 71,
                title: '2023 Edition Special Invitees',
                link: '/special-guests/'
            },
            {
                id: 72,
                title: '2023 Edition Partners',
                link: '/our-partners/'
            },
            {
                id: 73,
                title: '2023 Edition Winners',
                link: '/past-winners/'
            },
            {
                id: 73,
                title: 'Memories',
                link: '/memories-2023/'
            }
        ]
    },
    {
        id: 5,
        title: 'Coverage',
        submenu: [
            {
                id: 51,
                title: 'The FFTB Show',
                link: 'https://www.thefftbshow.com/'
            },
            {
                id: 52,
                title: 'News',
                link: '/newsroom/'
            },
            {
                id: 53,
                title: 'Blogs',
                link: '/blog/'
            },
            {
                id: 54,
                title: 'Awards',
                link: '/awards/'
            }
        ]
    },
    {
        id: 8,
        title: "Faq's",
        link: '/faq/',
    },
    {
        id: 9,
        title: "Why Participate ?",
        link: 'https://participate.forttuna.co/',
    },


]

const MobileMenu = () => {

    const [openId, setOpenId] = useState(0);
    const [menuActive, setMenuState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className={`mobile-menu-wrap ${menuActive ? "mobile-menu-visible" : ""}`}>
            <div className="mobile-nav-toggler" onClick={() => setMenuState(!menuActive)}><span className="icon flaticon-menu"></span></div>
            <div className="mobile-menu">
                <div className="menu-backdrop" onClick={() => setMenuState(!menuActive)}></div>
                <div className="close-btn" onClick={() => setMenuState(!menuActive)}><span className="icon flaticon-multiply"></span></div>
                <nav className="menu-box">
                <div class="nav-logo">
                    <a href="/" contenteditable="false" style={{Cursor: 'pointer'}}><img src={Logo} alt="" title="" /></a>
                </div>

                    <div className="menu-outer">
                        <ul className="navigation">

                            {menus.map((item, mn) => {
                                return (
                                    <ListItem className={item.id === openId ? 'active' : null} key={mn}>
                                        {item.submenu ?
                                            <Fragment>
                                                <li>
                                                    <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>{item.title}
                                                        <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
                                                    </p>
                                                </li>
                                                <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                                    <List className="subMenu">
                                                        <Fragment>
                                                            {item.submenu.map((submenu, i) => {
                                                                return (
                                                                    <ListItem key={i}>
                                                                        <Link onClick={ClickHandler} className="active"
                                                                            to={submenu.link}>{submenu.title}</Link>
                                                                    </ListItem>
                                                                )
                                                            })}
                                                        </Fragment>
                                                    </List>
                                                </Collapse>
                                            </Fragment>
                                            : <Link className="active"
                                                to={item.link}>{item.title}</Link>
                                        }
                                    </ListItem>
                                )
                            })}
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default MobileMenu;