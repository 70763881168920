import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Contactpage2 from '../../components/Contactpage2/Contactpage2';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import { Link } from 'react-router-dom';
import pIcon1 from '../../images/background/Contact-us-Banner.jpg';

const ContactPage2 =() => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Contact';
    }, []);
    return(
        <Fragment>
            <Helmet>
                <meta name="description" content="Contact Forttuna Global Excellence Awards for inquiries about our awards program. Join us in celebrating excellence across industries." />
                <meta property="og:title" content="Forttuna Global Excellence Awards | Contact" />
                <meta property="og:description" content="Contact Forttuna Global Excellence Awards for inquiries about our awards program. Join us in celebrating excellence across industries." />
                <meta property="og:image" content={hero1} />
                <meta property="og:url" content={hero1} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
            <section className="page-title banner-contact">
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Contact Us</div>
                            <h2 className="page-title_heading">Contact Us</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Contact Us</li>
                        </ul> */}
                    </div>
                </div>
            </section> 
            <Contactpage2/>
            <Subscribe2 />
            <Footer2 />
            <Scrollbar/>
        </Fragment>
    )
};
export default ContactPage2;

