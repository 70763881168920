import timg1 from '../images/winner/Akira.jpg'
import timg2 from '../images/winner/Akshay-Bhattacharjee.jpg'
import timg3 from '../images/winner/Ala-alsuwilah.jpg'
import timg4 from '../images/winner/Alexandra dermott.jpg'
import timg5 from '../images/winner/Alexandre Horvath.jpg'
import timg6 from '../images/winner/amani-albraikan.jpg'
import timg7 from '../images/winner/AMy-Lin.jpg'
import timg8 from '../images/winner/Andrew-soon-jin-kim.jpg'
import timg9 from '../images/winner/Anjlee.jpg'
import timg10 from '../images/winner/anthony-osborne.jpg'
import timg11 from '../images/winner/Arkesh-shah.jpg'
import timg12 from '../images/winner/Asma Jan Muhammad.jpg'
import timg13 from '../images/winner/Atrayee Seth.jpg'
import timg14 from '../images/winner/Avinish Jain.jpg'
import timg15 from '../images/winner/Babalola.jpg'
import timg16 from '../images/winner/Bala-Subramanyam.jpg'
import timg16b from '../images/winner/Bandana Kankani.jpg'
import timg17 from '../images/winner/Bas Geerdink.jpg'
import timg18 from '../images/winner/Bibhakar-Pandey.jpg'
import timg19 from '../images/winner/Bongani Maxwell Luthuli.jpg'
import timg20 from '../images/winner/Brenda Dempsey.jpg'
import timg21 from '../images/winner/Buddhini Samarakkody.jpg'
import timg22 from '../images/winner/Caroline Sparks.jpg'
import timg23 from '../images/winner/Christin Schafer.jpg'
import timg24 from '../images/winner/Dani-afioiuni.jpg'
import timg25 from '../images/winner/Daniel-Icenhour.jpg'
import timg26 from '../images/winner/David Burnet.jpg'
import timg27 from '../images/winner/Dr. Ebrahim Al Alkeem.jpg'
import timg28 from '../images/winner/Elisa Prisco.jpg'
import timg29 from '../images/winner/Kong Academy.jpg';
import timg30 from '../images/winner/Este Pretorius.jpg';
import timg31 from '../images/winner/Eunice Adjei.jpg';
import timg32 from '../images/winner/Fuad Aliyev.jpg';
import timg33 from '../images/winner/Dr.-Gary-Mangiofico.jpg';
import timg34 from '../images/winner/Gary Young.jpg';
import timg35 from '../images/winner/Gennie Sugene Gan.jpg';
import timg36 from '../images/winner/Jadeeyah Abang.jpg';
import timg37 from '../images/winner/Jagdish Kapuganti.jpg';
import timg38 from '../images/winner/Jason English.jpg';
import timg39 from '../images/winner/Jason Joseph.jpg';
import timg40 from '../images/winner/Joanna Brasset.jpg';
import timg41 from '../images/winner/Dr.-Puneet-Wadhwa.webp';
import timg42 from '../images/winner/Julia Linn.jpg';
import timg43 from '../images/winner/Juliane Knips.jpg';
import timg44 from '../images/winner/Kathrin.jpg';
import timg45 from '../images/winner/Kayley Taggart.jpg';
import timg46 from '../images/winner/Kimberly-Olson.jpg';
import timg47 from '../images/winner/Dr. Kiran Kewalramani.jpg';
import timg48 from '../images/winner/Lailayan Almasri.jpg';
import timg49 from '../images/winner/Leyla Allahverdiyeva.jpg';
import timg50 from '../images/winner/Dr. Linda Salvin.jpg';
import timg51 from '../images/winner/Lisa Mckee.jpg';
import timg52 from '../images/winner/Manish Sharma.jpg';
import timg53 from '../images/winner/Mansi Srivastava.jpg';
import timg54 from '../images/winner/Marc-Tissot.jpg';
import timg55 from '../images/winner/Maria Laws.jpg';
import timg56 from '../images/winner/Mark Scrimshire.jpg';
import timg57 from '../images/winner/Dr.-Mary-L.-Thomas.jpg';
import timg58 from '../images/winner/Mary Saliba.jpg';
import timg59 from '../images/winner/Mats Larsson.jpg';
import timg60 from '../images/winner/Mayada Maged.jpg';
import timg61 from '../images/winner/Memori Yamato.jpg';
import timg62 from '../images/winner/Michael-Lim.jpg';
import timg63 from '../images/winner/Michael Schreider.jpg';
import timg64 from '../images/winner/Mohamed Ibrahim.jpg';
import timg65 from '../images/winner/Muhammad Asif.jpg';
import timg66 from '../images/winner/Munira Rahman.jpg';
import timg67 from '../images/winner/Mustafa Mun.jpg';
import timg68 from '../images/winner/Nadim-Batista-Kuttab.jpg';
import timg69 from '../images/winner/Naim Maadad.jpg';
import timg70 from '../images/winner/Nilanthi Jayasinghe.jpg';
import timg71 from '../images/winner/Nobuaki Matsuoka.jpg';
import timg72 from '../images/winner/Oommen P Oommen.jpg';
import timg73 from '../images/winner/Paris Taylor.jpg';
import timg74 from '../images/winner/Pavan Pidugu.jpg';
import timg75 from '../images/winner/Peter Birdsall.jpg';
import timg76 from '../images/winner/Peter Monteza.jpg';
import timg77 from '../images/winner/Prasanna Chachan.jpg';
import timg78 from '../images/winner/Prashant Patade.jpg';
import timg79 from '../images/winner/Punith Suvarna.jpg';
import timg80 from '../images/winner/Rahul Chaudhary.jpg';
import timg81 from '../images/winner/Rajan Balakrishna Raje.jpg';
import timg82 from '../images/winner/Ramia Farrage.jpg';
import timg83 from '../images/winner/Rashi Khanna.jpg';
import timg84 from '../images/winner/Reena jain.jpg';
import timg85 from '../images/winner/Ridhwan Yusoff.jpg';
import timg86 from '../images/winner/Rosanich Sou.jpg';
import timg87 from '../images/winner/Sabina Aliyeva.jpg';
import timg88 from '../images/winner/Sagar Tejwani.jpg';
import timg89 from '../images/winner/Salman Almurshed.jpg';
import timg90 from '../images/winner/Samina Chaudhary.jpg';
import timg91 from '../images/winner/Sanket Mangrulkar.jpg';
import timg92 from '../images/winner/Sarita Patil.jpg';
import timg93 from '../images/winner/Sean Cooper.jpg';
import timg94 from '../images/winner/Shanza Khan.jpg';
import timg95 from '../images/winner/Sheena.jpg';
import timg96 from '../images/winner/Shinya Yamamoto.jpg';
import timg97 from '../images/winner/Shipra Yadav.jpg';
import timg98 from '../images/winner/Shiraz Jamaji.jpg';
import timg99 from '../images/winner/Stefan Ott.jpg';
import timg100 from '../images/winner/Dr. Steven Gan.jpg';
import timg101 from '../images/winner/Sukh Sandhu.jpg';
import timg102 from '../images/winner/Sultan Alameri.jpg';
import timg103 from '../images/winner/Sumit Srivastava.jpg';
import timg104 from '../images/winner/Suzan Alghanem.jpg';
import timg105 from '../images/winner/Tahani.jpg';
import timg106 from '../images/winner/Tashinga Nyahunda.jpg';
import timg107 from '../images/winner/Terrence Hooi.jpg';
import timg108 from '../images/winner/Tish Hodge.jpg';
import timg109 from '../images/winner/Victoria.jpg';
import timg110 from '../images/winner/Vincent Allen.jpg';
import timg111 from '../images/winner/Vishakha Taneja.jpg';
import timg112 from '../images/winner/Wael Al-Masri.jpg';
import timg113 from '../images/winner/Wolfgang.jpg';
import timg114 from '../images/winner/Zahra.jpg';



import tsimg1 from '../images/winner/Akira.jpg'
import tsimg2 from '../images/winner/Akshay-Bhattacharjee.jpg'
import tsimg3 from '../images/winner/Ala-alsuwilah.jpg'
import tsimg4 from '../images/winner/Alexandra dermott.jpg'
import tsimg5 from '../images/winner/Alexandre Horvath.jpg'
import tsimg6 from '../images/winner/amani-albraikan.jpg'
import tsimg7 from '../images/winner/AMy-Lin.jpg'
import tsimg8 from '../images/winner/Andrew-soon-jin-kim.jpg'
import tsimg9 from '../images/winner/Anjlee.jpg'
import tsimg10 from '../images/winner/anthony-osborne.jpg'
import tsimg11 from '../images/winner/Arkesh-shah.jpg'
import tsimg12 from '../images/winner/Asma Jan Muhammad.jpg'
import tsimg13 from '../images/winner/Atrayee Seth.jpg'
import tsimg14 from '../images/winner/Avinish Jain.jpg'
import tsimg15 from '../images/winner/Babalola.jpg'
import tsimg16 from '../images/winner/Bala-Subramanyam.jpg'
import tsimg16b from '../images/winner/Bandana Kankani.jpg'
import tsimg17 from '../images/winner/Bas Geerdink.jpg'
import tsimg18 from '../images/winner/Bibhakar-Pandey.jpg'
import tsimg19 from '../images/winner/Bongani Maxwell Luthuli.jpg'
import tsimg20 from '../images/winner/Brenda Dempsey.jpg'
import tsimg21 from '../images/winner/Buddhini Samarakkody.jpg'
import tsimg22 from '../images/winner/Caroline Sparks.jpg'
import tsimg23 from '../images/winner/Christin Schafer.jpg'
import tsimg24 from '../images/winner/Dani-afioiuni.jpg'
import tsimg25 from '../images/winner/Daniel-Icenhour.jpg'
import tsimg26 from '../images/winner/David Burnet.jpg'
import tsimg27 from '../images/winner/Dr. Ebrahim Al Alkeem.jpg'
import tsimg28 from '../images/winner/Elisa Prisco.jpg'
import tsimg29 from '../images/winner/Kong Academy.jpg'
import tsimg30 from '../images/winner/Este Pretorius.jpg';
import tsimg31 from '../images/winner/Eunice Adjei.jpg';
import tsimg32 from '../images/winner/Fuad Aliyev.jpg';
import tsimg33 from '../images/winner/Dr.-Gary-Mangiofico.jpg';
import tsimg34 from '../images/winner/Gary Young.jpg';
import tsimg35 from '../images/winner/Gennie Sugene Gan.jpg';
import tsimg36 from '../images/winner/Jadeeyah Abang.jpg';
import tsimg37 from '../images/winner/Jagdish Kapuganti.jpg';
import tsimg38 from '../images/winner/Jason English.jpg';
import tsimg39 from '../images/winner/Jason Joseph.jpg';
import tsimg40 from '../images/winner/Joanna Brasset.jpg';
import tsimg41 from '../images/winner/Dr.-Puneet-Wadhwa.webp';
import tsimg42 from '../images/winner/Julia Linn.jpg';
import tsimg43 from '../images/winner/Juliane Knips.jpg';
import tsimg44 from '../images/winner/Kathrin.jpg';
import tsimg45 from '../images/winner/Kayley Taggart.jpg';
import tsimg46 from '../images/winner/Kimberly-Olson.jpg';
import tsimg47 from '../images/winner/Dr. Kiran Kewalramani.jpg';
import tsimg48 from '../images/winner/Lailayan Almasri.jpg';
import tsimg49 from '../images/winner/Leyla Allahverdiyeva.jpg';
import tsimg50 from '../images/winner/Dr. Linda Salvin.jpg';
import tsimg51 from '../images/winner/Lisa Mckee.jpg';
import tsimg52 from '../images/winner/Manish Sharma.jpg';
import tsimg53 from '../images/winner/Mansi Srivastava.jpg';
import tsimg54 from '../images/winner/Marc-Tissot.jpg';
import tsimg55 from '../images/winner/Maria Laws.jpg';
import tsimg56 from '../images/winner/Mark Scrimshire.jpg';
import tsimg57 from '../images/winner/Dr.-Mary-L.-Thomas.jpg';
import tsimg58 from '../images/winner/Mary Saliba.jpg';
import tsimg59 from '../images/winner/Mats Larsson.jpg';
import tsimg60 from '../images/winner/Mayada Maged.jpg';
import tsimg61 from '../images/winner/Memori Yamato.jpg';
import tsimg62 from '../images/winner/Michael-Lim.jpg';
import tsimg63 from '../images/winner/Michael Schreider.jpg';
import tsimg64 from '../images/winner/Mohamed Ibrahim.jpg';
import tsimg65 from '../images/winner/Muhammad Asif.jpg';
import tsimg66 from '../images/winner/Munira Rahman.jpg';
import tsimg67 from '../images/winner/Mustafa Mun.jpg';
import tsimg68 from '../images/winner/Nadim-Batista-Kuttab.jpg';
import tsimg69 from '../images/winner/Naim Maadad.jpg';
import tsimg70 from '../images/winner/Nilanthi Jayasinghe.jpg';
import tsimg71 from '../images/winner/Nobuaki Matsuoka.jpg';
import tsimg72 from '../images/winner/Oommen P Oommen.jpg';
import tsimg73 from '../images/winner/Paris Taylor.jpg';
import tsimg74 from '../images/winner/Pavan Pidugu.jpg';
import tsimg75 from '../images/winner/Peter Birdsall.jpg';
import tsimg76 from '../images/winner/Peter Monteza.jpg';
import tsimg77 from '../images/winner/Prasanna Chachan.jpg';
import tsimg78 from '../images/winner/Prashant Patade.jpg';
import tsimg79 from '../images/winner/Punith Suvarna.jpg';
import tsimg80 from '../images/winner/Rahul Chaudhary.jpg';
import tsimg81 from '../images/winner/Rajan Balakrishna Raje.jpg';
import tsimg82 from '../images/winner/Ramia Farrage.jpg';
import tsimg83 from '../images/winner/Rashi Khanna.jpg';
import tsimg84 from '../images/winner/Reena jain.jpg';
import tsimg85 from '../images/winner/Ridhwan Yusoff.jpg';
import tsimg86 from '../images/winner/Rosanich Sou.jpg';
import tsimg87 from '../images/winner/Sabina Aliyeva.jpg';
import tsimg88 from '../images/winner/Sagar Tejwani.jpg';
import tsimg89 from '../images/winner/Salman Almurshed.jpg';
import tsimg90 from '../images/winner/Samina Chaudhary.jpg';
import tsimg91 from '../images/winner/Sanket Mangrulkar.jpg';
import tsimg92 from '../images/winner/Sarita Patil.jpg';
import tsimg93 from '../images/winner/Sean Cooper.jpg';
import tsimg94 from '../images/winner/Shanza Khan.jpg';
import tsimg95 from '../images/winner/Sheena.jpg';
import tsimg96 from '../images/winner/Shinya Yamamoto.jpg';
import tsimg97 from '../images/winner/Shipra Yadav.jpg';
import tsimg98 from '../images/winner/Shiraz Jamaji.jpg';
import tsimg99 from '../images/winner/Stefan Ott.jpg';
import tsimg100 from '../images/winner/Dr. Steven Gan.jpg';
import tsimg101 from '../images/winner/Sukh Sandhu.jpg';
import tsimg102 from '../images/winner/Sultan Alameri.jpg';
import tsimg103 from '../images/winner/Sumit Srivastava.jpg';
import tsimg104 from '../images/winner/Suzan Alghanem.jpg';
import tsimg105 from '../images/winner/Tahani.jpg';
import tsimg106 from '../images/winner/Tashinga Nyahunda.jpg';
import tsimg107 from '../images/winner/Terrence Hooi.jpg';
import tsimg108 from '../images/winner/Tish Hodge.jpg';
import tsimg109 from '../images/winner/Victoria.jpg';
import tsimg110 from '../images/winner/Vincent Allen.jpg';
import tsimg111 from '../images/winner/Vishakha Taneja.jpg';
import tsimg112 from '../images/winner/Wael Al-Masri.jpg';
import tsimg113 from '../images/winner/Wolfgang.jpg';
import tsimg114 from '../images/winner/Zahra.jpg';



const Teams = [
   {
      Id: '1',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Dr. Akira Mitsumasu',
      slug: 'Dr-Akira-Mitsumasu',
      indu : 'Aviation',
      title: 'Business Leader of the Year, Saudi Arabia',
   },
   {
      Id: '2',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Akshay Bhattacharjee',
      slug: 'Akshay-Bhattacharjee',
      indu : 'Marketing',
      title: 'Marketing Leader of the Year, Germany',
   },
   {
      Id: '3',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Alaa Alsuwilah',
      slug: 'Alaa-Alsuwilah',
      indu : 'Education',
      title: 'Business Leader of the Year, Saudi Arabia',
   },
   {
      Id: '4',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr. Alexandra McDermott',
      slug: 'Dr-Alexandra-McDermott',
      indu : 'Education',
      title: 'Entrepreneur of the Year, United States',
   },
   {
      Id: '5',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Alexandre Horvath',
      slug: 'Alexandre-Horvath',
      indu : 'Technology',
      title: 'Business Leader of the Year, Switzerland',
   },
   {
      Id: '6',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Amani Albraikan',
      slug: 'Amani-Albraikan',
      indu : 'Technology',
      title: 'Innovative Leader of the Year, Saudi Arabia',
   },
   {
      Id: '7',
      tImg: timg7,
      tsImg: tsimg7,
      name: 'Amy Lin',
      slug: 'Amy-Lin',
      indu : 'Education',
      title: 'CEO of the Year, United States',
   },
   {
      Id: '8',
      tImg: timg8,
      tsImg: tsimg8,
      name: 'Andrew Se Joon Kim',
      slug: 'Andrew-Se-Joon-Kim',
      indu : 'Technology',
      title: 'Business Leader of the Year, Canada',
   },
   {
      Id: '9',
      tImg: timg9,
      tsImg: tsimg9,
      name: 'Dr. Anjlee Prakash',
      slug: 'Dr-Anjlee-Prakash',
      indu : 'Education',
      title: 'Lifetime Achievement Award, India',
   },
   {
      Id: '10',
      tImg: timg10,
      tsImg: tsimg10,
      name: 'Anthony Osborne',
      slug: 'Anthony-Osborne',
      indu : 'Technology',
      title: 'Business Leader of the Year, Singapore',
   },
   {
      Id: '11',
      tImg: timg11,
      tsImg: tsimg11,
      name: 'Arkesh Shah',
      slug: 'Arkesh-Shah',
      indu : 'Fashion and Beauty',
      title: 'Brand Leader of the Year, United Kingdom',
   },
   {
      Id: '12',
      tImg: timg12,
      tsImg: tsimg12,
      name: 'Asma Jan Muhammad',
      slug: 'Asma-Jan-Muhammad',
      indu : 'Education',
      title: 'Emerging Author of the Year, United Arab Emirates',
   },
   {
      Id: '13',
      tImg: timg13,
      tsImg: tsimg13,
      name: 'Atrayee Seth',
      slug: 'Atrayee-Seth',
      indu : 'Fashion and Beauty',
      title: 'Emerging Business Leader of the Year, India',
   },
   {
      Id: '14',
      tImg: timg14,
      tsImg: tsimg14,
      name: 'Avinish Jain',
      slug: 'Avinish-Jain',
      indu : 'Marketing',
      title: 'Emerging Business Leader of the Year, Kenya',
   },
   {
      Id: '15',
      tImg: timg15,
      tsImg: tsimg15,
      name: 'Babalola Taiwo Timothy',
      slug: 'Babalola-Taiwo-Timothy',
      indu : 'Technology',
      title: 'Business Leader of the Year, Qatar',
   },
   {
      Id: '16',
      tImg: timg16,
      tsImg: tsimg16,
      name: 'Bala Subramanyam',
      slug: 'Bala-Subramanyam',
      indu : 'Fashion and Beauty',
      title: 'Business Leader of the Year, Kuwait',
   },
   {
      Id: '16',
      tImg: timg16b,
      tsImg: tsimg16b,
      name: 'Bandana Kankani',
      slug: 'Bandana Kankani',
      indu : 'Finance and Insurance',
      title: 'Business Leader of the Year, India',
   },
   {
      Id: '17',
      tImg: timg17,
      tsImg: tsimg17,
      name: 'Bas Geerdink',
      slug: 'Bas-Geerdink',
      indu : 'Technology',
      title: 'Technology Leader of the Year, Netherlands',
   },
   {
      Id: '18',
      tImg: timg18,
      tsImg: tsimg18,
      name: 'Bibhakar Pandey',
      slug: 'Bibhakar-Pandey',
      indu : 'Technology',
      title: 'Mentor of the Year, United States',
   },
   {
      Id: '19',
      tImg: timg19,
      tsImg: tsimg19,
      name: 'Bongani Maxwell Luthuli',
      slug: 'Bongani-Maxwell-Luthuli',
      indu : 'Law',
      title: 'Corporate Lawyer of the Year, South Africa',
   },
   {
      Id: '20',
      tImg: timg20,
      tsImg: tsimg20,
      name: 'Brenda Dempsey',
      slug: 'Brenda-Dempsey',
      indu : 'Education',
      title: 'Lifetime Achievement Award, United Kingdom',
   },
   {
      Id: '21',
      tImg: timg21,
      tsImg: tsimg21,
      name: 'Buddhini Samarakkody',
      slug: 'Buddhini-Samarakkody',
      indu : 'Education',
      title: 'Mentor of the Year, Sri Lanka',
   },
   {
      Id: '22',
      tImg: timg22,
      tsImg: tsimg22,
      name: 'Turtle Tots',
      slug: 'Turtle-Tots',
      indu : 'Education',
      title: 'Education Company of the Year, United Kingdom',
   },
   {
      Id: '23',
      tImg: timg23,
      tsImg: tsimg23,
      name: 'Christin Schäfer',
      slug: 'Christin-Schäfer',
      indu : 'Technology',
      title: 'Business Woman of the Year, Germany',
   },
   {
      Id: '24',
      tImg: timg24,
      tsImg: tsimg24,
      name: 'Dani Afiouni',
      slug: 'Dani-Afiouni',
      indu : 'Marketing',
      title: 'Emerging Business Leader of the Year, United Arab Emirates',
   },
   {
      Id: '25',
      tImg: timg25,
      tsImg: tsimg25,
      name: 'Daniel Icenhour',
      slug: 'Daniel-Icenhour',
      indu : 'Wellness',
      title: 'Business Leader of the Year, United States',
   },
   {
      Id: '26',
      tImg: timg26,
      tsImg: tsimg26,
      name: 'David Burnet',
      slug: 'David-Burnet',
      indu : 'Technology',
      title: 'Young Entrepreneur of the Year, United Kingdom',
   },
   {
      Id: '27',
      tImg: timg27,
      tsImg: tsimg27,
      name: 'Dr. Ebrahim Al Alkeem',
      slug: 'Dr-Ebrahim-Al-Alkeem',
      indu : 'Technology',
      title: 'AI Leader of the Year, United Arab Emirates',
   },
   {
      Id: '28',
      tImg: timg28,
      tsImg: tsimg28,
      name: 'Elisa Prisco',
      slug: 'Elisa-Prisco',
      indu : 'Education',
      title: 'Educator of the Year, Italy',
   },
   {
      Id: '29',
      tImg: timg29,
      tsImg: tsimg29,
      name: 'Elke Robshaw',
      slug: 'Elke-Robshaw',
      indu : 'Education',
      com:'Kong Academy',
      title: 'Education Company of the Year, United States',
   },
   {
      Id: '30',
      tImg: timg30,
      tsImg: tsimg30,
      name: 'Este Pretorius',
      slug: 'Este-Pretorius',
      indu : 'Marketing',
      title: 'CEO of the Year, South Africa',
   },
   {
      Id: '31',
      tImg: timg31,
      tsImg: tsimg31,
      name: 'Eunice Adjei',
      slug: 'Eunice-Adjei',
      indu : 'Education',
      title: 'Most Influential Young Educator of the Year, United States',
   },
   {
      Id: '32',
      tImg: timg32,
      tsImg: tsimg32,
      name: 'Fuad Aliyev',
      slug: 'Fuad-Aliyev',
      indu : 'Aviation',
      title: 'Young Business Leader of the Year, Germany',
   },
   {
      Id: '33',
      tImg: timg33,
      tsImg: tsimg33,
      name: 'Dr. Gary Mangiofico',
      slug: 'Dr-Gary-Mangiofico',
      indu : 'Education',
      title: 'Social Innovation Leader of the Year, United States',
   },
   {
      Id: '34',
      tImg: timg34,
      tsImg: tsimg34,
      name: 'Gary Young',
      slug: 'Gary-Young',
      indu : 'Manufacturing',
      title: 'Lifetime Achievement Award, United States',
   },
   {
      Id: '35',
      tImg: timg35,
      tsImg: tsimg35,
      name: 'Dr. Genie Sugene Gan',
      slug: 'Dr-Genie-Sugene-Gan',
      indu : 'Technology',
      title: 'Business Woman of the Year, Singapore',
   },
   {
      Id: '36',
      tImg: timg36,
      tsImg: tsimg36,
      name: 'Jadeeyah Abang',
      slug: 'Jadeeyah-Abang',
      indu : 'Marketing',
      title: 'Business Woman of the Year, Philippines',
   },
   {
      Id: '37',
      tImg: timg37,
      tsImg: tsimg37,
      name: 'Dr. Jagadis Gupta Kapuganti',
      slug: 'Dr-Jagadis-Gupta-Kapuganti',
      indu : 'Technology',
      com:'Fruvetech',
      title: 'Start-up of the Year, India',
   },
   {
      Id: '38',
      tImg: timg38,
      tsImg: tsimg38,
      name: 'Jason English',
      slug: 'Jason-English',
      indu : 'Architecture/Construction/Engineering',
      title: 'Business Leader of the Year, United Arab Emirates',
   },
   {
      Id: '39',
      tImg: timg39,
      tsImg: tsimg39,
      name: 'Jason Joseph',
      slug: 'Jason-Joseph',
      indu : 'Technology',
      title: 'CIO of the Year, India',
   },
   {
      Id: '40',
      tImg: timg40,
      tsImg: tsimg40,
      name: 'Joanna Brassett',
      slug: 'Joanna-Brassett',
      indu : 'Market Research',
      title: 'Market Research Company of the Year, United Kingdom',
      com:'Studio intO',
      title2: 'CEO of the Year, Poland',
   },
   // {
   //    Id: '41',
   //    tImg: timg41,
   //    tsImg: tsimg41,
   //    name: 'Joanna Brassett',
   //    slug: 'Joanna-Brassett',
   //    indu : 'Market Research',
   // },
   {
      Id: '42',
      tImg: timg42,
      tsImg: tsimg42,
      name: 'Julia Linn',
      slug: 'Julia-Linn',
      indu : "Education",
      title: 'Most Inspiring Business Leader of the Year, United States',
   },
   {
      Id: '43',
      tImg: timg43,
      tsImg: tsimg43,
      name: 'Juliane Knips',
      slug: 'Juliane-Knips',
      indu : "Fashion and Beauty",
      title: 'Cultural Heritage Fashion Leader of the Year, United Arab Emirates',
   },
   {
      Id: '44',
      tImg: timg44,
      tsImg: tsimg44,
      name: 'Dr. Kathrin Gabriele Kind-trueller',
      slug: 'Dr-Kathrin-Gabriele-Kind-trueller',
      indu : "SME's",
      title: 'Business Woman of the Year, Norway',
   },
   {
      Id: '45',
      tImg: timg45,
      tsImg: tsimg45,
      name: 'Kayley A. Taggart',
      slug: 'Kayley-A-Taggart',
      indu : "SME's",
      title: 'Entrepreneur of the Year, United Kingdom',
   },
   {
      Id: '46',
      tImg: timg46,
      tsImg: tsimg46,
      name: 'Kimberly Olson',
      slug: 'Kimberly-Olson',
      indu : "Marketing",
      title: 'Business Woman of the Year, United States',
   },
   {
      Id: '47',
      tImg: timg47,
      tsImg: tsimg47,
      name: 'Dr. Kiran Kewalramani',
      slug: 'Dr-Kiran-Kewalramani',
      indu : 'Technology',
      com:'Cyber Ethos',
      title: 'Cybersecurity Business of the Year, Australia',
   },
   {
      Id: '48',
      tImg: timg48,
      tsImg: tsimg48,
      name: 'Lailayan Almasri',
      slug: 'Lailayan-Almasri',
      indu : 'Marketing',
      title: 'CMO of the Year, United Arab Emirates',
   },
   {
      Id: '49',
      tImg: timg49,
      tsImg: tsimg49,
      name: 'Leyla Allahverdiyeva',
      slug: 'Leyla-Allahverdiyeva',
      indu : 'Hospitality',
      title: 'Business Woman of the Year, Switzerland',
   },
   {
      Id: '50',
      tImg: timg50,
      tsImg: tsimg50,
      name: 'Dr. Linda Salvin',
      slug: 'Dr-Linda-Salvin',
      indu : 'Entertainment and Media',
      title: 'Business Woman of the Year, United States',
   },
   {
      Id: '51',
      tImg: timg51,
      tsImg: tsimg51,
      name: 'Dr. Lisa Mckee',
      slug: 'Dr-Lisa-Mckee',
      indu : 'Technology',
      title: 'Data Privacy Leader of the Year, United States',
   },
   {
      Id: '52',
      tImg: timg52,
      tsImg: tsimg52,
      name: 'Manish Sharma',
      slug: 'Manish-Sharma',
      indu : 'Technology',
      title: 'Entrepreneur of the Year, India',
   },
   {
      Id: '53',
      tImg: timg53,
      tsImg: tsimg53,
      name: 'Mansi Srivastava',
      slug: 'Mansi-Srivastava',
      indu : 'Education',
      title: 'Education Leader of the Year, Indonesia',
   },
   {
      Id: '54',
      tImg: timg54,
      tsImg: tsimg54,
      name: 'Marc Tissot',
      slug: 'Marc-Tissot',
      indu : 'Finance and Insurance',
      title: 'Business Leader of the Year, Switzerland',
   },
   {
      Id: '55',
      tImg: timg55,
      tsImg: tsimg55,
      name: 'Maria Laws',
      slug: 'Maria-Laws',
      indu : 'Education',
      title: 'Learning & Development Leader of the Year, United States',
   },
   {
      Id: '56',
      tImg: timg56,
      tsImg: tsimg56,
      name: 'Mark Scrimshire',
      slug: 'Mark-Scrimshire',
      indu : 'Technology',
      title: 'Chief Interoperability Officer of the Year, United States',
   },
   {
      Id: '57',
      tImg: timg57,
      tsImg: tsimg57,
      name: 'Dr. Mary L. Thomas',
      slug: 'Dr-Mary-L-Thomas',
      indu : 'Education',
      title: 'Business Woman of the Year, United States',
   },
   {
      Id: '58',
      tImg: timg58,
      tsImg: tsimg58,
      name: 'Mary Saliba',
      slug: 'Mary-Saliba',
      indu : 'Education',
      title: 'Young Business Leader of the Year, Denmark',
   },
   {
      Id: '59',
      tImg: timg59,
      tsImg: tsimg59,
      name: 'Mats Larsson',
      slug: 'Mats-Larsson',
      indu : 'Hospitality',
      title: 'Entrepreneur of the Year, Sweden',
   },
   {
      Id: '60',
      tImg: timg60,
      tsImg: tsimg60,
      name: 'Mayada Maged',
      slug: 'Mayada-Maged',
      indu : 'Human Resources',
      title: 'HR Outsourcing Leader of the Year, Egypt',
   },
   {
      Id: '61',
      tImg: timg61,
      tsImg: tsimg61,
      name: 'Memori Yamato',
      slug: 'Memori-Yamato',
      indu : 'Technology',
      title: 'Young Business Leader of the Year, Japan',
   },
   {
      Id: '62',
      tImg: timg62,
      tsImg: tsimg62,
      name: 'Michael Lim',
      slug: 'Michael-Lim',
      indu : 'Entertainment and Media',
      title: 'Film and Television Production Leader of the Year, Singapore',
   },
   {
      Id: '63',
      tImg: timg63,
      tsImg: tsimg63,
      name: 'Michael Schreider',
      slug: 'Michael-Schreider',
      indu : 'Education',
      title: 'Mentor of the Year, Saudi Arabia',
   },
   {
      Id: '64',
      tImg: timg64,
      tsImg: tsimg64,
      name: 'Mohamed Ibrahim',
      slug: 'Mohamed-Ibrahim',
      indu : 'Technology',
      com:'Lahint',
      title: 'Start-up of the Year, Saudi Arabia',
   },
   {
      Id: '65',
      tImg: timg65,
      tsImg: tsimg65,
      name: 'Dr. Muhammad Asif',
      slug: 'Dr-Muhammad-Asif',
      indu : 'Technology',
      title: 'Young Business Leader of the Year, Pakistan',
   },
   {
      Id: '66',
      tImg: timg66,
      tsImg: tsimg66,
      name: 'Munira Rahman',
      slug: 'Munira-Rahman',
      indu : 'Fashion and Beauty',
      title: 'Business Woman of the Year, United Arab Emirates',
   },
   {
      Id: '67',
      tImg: timg67,
      tsImg: tsimg67,
      name: 'Mustafa Mun',
      slug: 'Mustafa-Mun',
      indu : 'Fashion and Beauty',
      title: 'Business Leader of the Year, India',
   },
   {
      Id: '68',
      tImg: timg68,
      tsImg: tsimg68,
      name: 'Nadim Batista-Kuttab',
      slug: 'Nadim-Batista-Kuttab',
      indu : 'Marketing',
      title: 'CEO of the Year, Germany',
   },
   {
      Id: '69',
      tImg: timg69,
      tsImg: tsimg69,
      name: 'Dr. Naim Maadad',
      slug: 'Dr-Naim-Maadad',
      indu : 'Hospitality',
      title: 'Business Leader of the Year, United Arab Emirates',
   },
   {
      Id: '70',
      tImg: timg70,
      tsImg: tsimg70,
      name: 'Nilanthi Jayasinghe',
      slug: 'Nilanthi-Jayasinghe',
      indu : 'Hospitality',
      title: 'Travel Influencer of the Year, Sri Lanka',
   },
   {
      Id: '71',
      tImg: timg71,
      tsImg: tsimg71,
      name: 'Nobuaki Uehara Matsuoka',
      slug: 'Nobuaki-Uehara-Matsuoka',
      indu : 'Fashion and Beauty',
      title: 'Business Leader of the Year, Singapore',
   },
   {
      Id: '72',
      tImg: timg72,
      tsImg: tsimg72,
      name: 'Oommen P. Oommen',
      slug: 'Oommen-P-Oommen',
      indu : 'Technology',
      title: 'AVP of the Year, United Arab Emirates',
   },
   {
      Id: '73',
      tImg: timg73,
      tsImg: tsimg73,
      name: 'Paris Taylor',
      slug: 'Paris-Taylor',
      indu : 'Human Resources',
      title: 'Business Woman of the Year, United Kingdom',
   },
   {
      Id: '74',
      tImg: timg74,
      tsImg: tsimg74,
      name: 'Pavan Pidugu',
      slug: 'Pavan-Pidugu',
      indu : 'Technology',
      title: 'CTO of the Year, United States',
   },
   {
      Id: '75',
      tImg: timg75,
      tsImg: tsimg75,
      name: 'Peter Birdsall',
      slug: 'Peter-Birdsall',
      indu : 'Education',
      title: 'Business Leader of the Year, Netherlands',
   },
   {
      Id: '76',
      tImg: timg76,
      tsImg: tsimg76,
      name: 'Peter Monteza',
      slug: 'Peter-Monteza',
      indu : "Technology",
      title: 'Young CEO of the Year, United Kingdom',
   },
   {
      Id: '77',
      tImg: timg77,
      tsImg: tsimg77,
      name: 'Dr. Prasanna Chachan',
      slug: 'Dr-Prasanna-Chachan',
      indu : 'Manufacturing',
      title: 'Entrepreneur of the Year, United Arab Emirates',
   },
   {
      Id: '78',
      tImg: timg78,
      tsImg: tsimg78,
      name: 'Prashant Patade',
      slug: 'Prashant-Patade',
      indu : "SME's",
      com:'Traceart Technologies',
      title: 'SME of the Year, India',
   },
   {
      Id: '79',
      tImg: timg79,
      tsImg: tsimg79,
      name: 'Punith Suvarna',
      slug: 'Punith-Suvarna',
      indu : "Human Resources",
      title: 'Talent Acquisition Leader of the Year, United States',
   },
   {
      Id: '80',
      tImg: timg80,
      tsImg: tsimg80,
      name: 'Dr. Rahul Chaudhary',
      slug: 'Dr-Rahul-Chaudhary',
      indu : 'Fashion and Beauty',
      title: 'Entrepreneur of the Year, India',
   },
   {
      Id: '81',
      tImg: timg81,
      tsImg: tsimg81,
      name: 'Rajan Balkrishna Raje',
      slug: 'Rajan-Balkrishna-Raje',
      indu : "SME's",
      title: 'Business Leader of the Year, India',
   },
   {
      Id: '82',
      tImg: timg82,
      tsImg: tsimg82,
      name: 'Ramia Farrage',
      slug: 'Ramia-Farrage',
      indu : 'Entertainment and Media',
      title: 'Presenter of the Year, United Arab Emirates',
   },
   {
      Id: '83',
      tImg: timg83,
      tsImg: tsimg83,
      name: 'Rashi Khanna',
      slug: 'Rashi-Khanna',
      indu : 'Entertainment and Media',
      title: 'Business Woman of the Year, India',
   },
   {
      Id: '84',
      tImg: timg84,
      tsImg: tsimg84,
      name: 'Reena Jain',
      slug: 'Reena-Jain',
      indu : 'Technology',
      title: 'Young Technology Leader of the Year, United States',
   },
   {
      Id: '85',
      tImg: timg85,
      tsImg: tsimg85,
      name: 'Ridhwan Yusoff',
      slug: 'Ridhwan-Yusoff',
      indu : 'Education',
      title: 'Mentor of the Year, Singapore',
   },
   {
      Id: '86',
      tImg: timg86,
      tsImg: tsimg86,
      name: 'Rosanich Sou',
      slug: 'Rosanich-Sou',
      indu : 'Marketing',
      title: 'Emerging Business Leader of the Year, Cambodia',
   },
   {
      Id: '87',
      tImg: timg87,
      tsImg: tsimg87,
      name: 'Sabina Aliyeva',
      slug: 'Sabina-Aliyeva',
      indu : 'Architecture/Construction/Engineering',
      title: 'Interior Designer of the Year, United Arab Emirates',
   },
   {
      Id: '88',
      tImg: timg88,
      tsImg: tsimg88,
      name: 'Sagar Bijendra Tejwani',
      slug: 'Sagar-Bijendra-Tejwani',
      indu : 'Finance and Insurance',
      title: 'Financial Markets Leader of the Year, United Arab Emirates',
   },
   {
      Id: '89',
      tImg: timg89,
      tsImg: tsimg89,
      name: 'Salman M. Almurshed',
      slug: 'Salman-M-Almurshed',
      indu : 'Finance and Insurance',
      title: 'Business Leader of the Year, Saudi Arabia',
   },
   {
      Id: '90',
      tImg: timg90,
      tsImg: tsimg90,
      name: 'Samina Chowdhury',
      slug: 'Samina-Chowdhury',
      indu : 'Real Estate',
      title: 'Business Woman of the Year, United States',
   },
   {
      Id: '91',
      tImg: timg91,
      tsImg: tsimg91,
      name: 'Sanket Mangrulkar',
      slug: 'Sanket-Mangrulkar',
      indu : 'Technology',
      title: 'CFO of the Year, United Arab Emirates',
   },
   {
      Id: '92',
      tImg: timg92,
      tsImg: tsimg92,
      name: 'Sarita Patil',
      slug: 'Sarita-Patil',
      indu : 'Entertainment and Media',
      title: 'Content Leader of the Year, India',
   },
   {
      Id: '93',
      tImg: timg93,
      tsImg: tsimg93,
      name: 'Sean Cooper',
      slug: 'Sean-Cooper',
      indu : 'Technology',
      title: 'Cybersecurity Leader of the Year, United States',
   },
   {
      Id: '94',
      tImg: timg94,
      tsImg: tsimg94,
      name: 'Shanza N. Khan',
      slug: 'Shanza-N-Khan',
      indu : 'Education',
      title: 'Business Woman of the Year, Pakistan',
   },
   {
      Id: '95',
      tImg: timg95,
      tsImg: tsimg95,
      name: 'Sheena Alaiasa',
      slug: 'Sheena-Alaiasa',
      indu : 'Education',
      title: 'Most Innovative Leader of the Year, United States',
   },
   {
      Id: '96',
      tImg: timg96,
      tsImg: tsimg96,
      name: 'Dr. Shinya Yamamoto',
      slug: 'Dr-Shinya-Yamamoto',
      indu : 'Technology',
      title: 'Entrepreneur of the Year, Japan',
   },
   {
      Id: '97',
      tImg: timg97,
      tsImg: tsimg97,
      name: 'Shipra Yadav',
      slug: 'Shipra-Yadav',
      indu : 'Education',
      title: 'Educator of the Year, United Arab Emirates',
   },
   {
      Id: '98',
      tImg: timg98,
      tsImg: tsimg98,
      name: 'Shiraz Adarbad Jamaji',
      slug: 'Shiraz-Adarbad-Jamaji',
      indu : 'Manufacturing',
      title: 'Business Leader of the Year, United Arab Emirates',
   },
   {
      Id: '99',
      tImg: timg99,
      tsImg: tsimg99,
      name: 'Stefan Ott',
      slug: 'Stefan-Ott',
      indu : 'Automobile',
      title: 'Entrepreneur of the Year, Germany',
   },
   {
      Id: '100',
      tImg: timg100,
      tsImg: tsimg100,
      name: 'Dr. Steven Gan',
      slug: 'Dr-Steven-Gan',
      indu : 'Finance and Insurance',
      title: 'CTO of the Year, Malaysia',
   },
   {
      Id: '101',
      tImg: timg101,
      tsImg: tsimg101,
      name: 'Sukh Sandhu',
      slug: 'Sukh-Sandhu',
      indu : 'Education',
      title: 'Business Leader of the Year, Australia',
   },
   {
      Id: '102',
      tImg: timg102,
      tsImg: tsimg102,
      name: 'Sultan Alameri',
      slug: 'Sultan-Alameri',
      indu : 'Education',
      title: 'Deputy Vice-Chancellor of the Year, United Arab Emirates',
   },
   {
      Id: '103',
      tImg: timg103,
      tsImg: tsimg103,
      name: 'Sumit Srivastava',
      slug: 'Sumit-Srivastava',
      indu : 'Technology',
      title: 'Digital Disruptor of the Year, United Arab Emirates',
   },
   {
      Id: '104',
      tImg: timg104,
      tsImg: tsimg104,
      name: 'Suzan Alghanem',
      slug: 'Suzan-Alghanem',
      indu : 'Governance',
      title: 'Emerging Business Leader of the Year, United Arab Emirates',
   },
   {
      Id: '105',
      tImg: timg105,
      tsImg: tsimg105,
      name: 'Tahani Daghistani',
      slug: 'Tahani-Daghistani',
      indu : 'Technology',
      title: 'Business Leader of the Year, Saudi Arabia',
   },
   {
      Id: '106',
      tImg: timg106,
      tsImg: tsimg106,
      name: 'Tashinga Nyahunda',
      slug: 'Tashinga-Nyahunda',
      indu : 'Marketing',
      title: 'Marketing Strategist of the Year, South Africa',
   },
   {
      Id: '107',
      tImg: timg107,
      tsImg: tsimg107,
      name: 'Terrence Hooi',
      slug: 'Terrence-Hooi',
      indu : 'Technology',
      title: 'Entrepreneur of the Year, Hong Kong',
   },
   {
      Id: '108',
      tImg: timg108,
      tsImg: tsimg108,
      name: 'Tish Hodge',
      slug: 'Tish-Hodge',
      indu : 'Human Resources',
      title: 'Mentor of the Year, United States',
   },
   {
      Id: '109',
      tImg: timg109,
      tsImg: tsimg109,
      name: 'Dr. Victoria Schulman',
      slug: 'Dr-Victoria-Schulman',
      indu : 'Education',
      title: 'Mentor of the Year, United States',
   },
   {
      Id: '110',
      tImg: timg110,
      tsImg: tsimg110,
      name: 'Dr. Vincent Allen',
      slug: 'Dr-Vincent-Allen',
      indu : 'Entertainment and Media',
      title: 'Emerging Business Leader of the Year, United States',
   },
   {
      Id: '111',
      tImg: timg111,
      tsImg: tsimg111,
      name: 'Vishaka Taneja',
      slug: 'Vishaka-Taneja',
      indu : 'Finance and Insurance',
      title: 'Business Woman of the Year, United Arab Emirates',
   },
   {
      Id: '112',
      tImg: timg112,
      tsImg: tsimg112,
      name: 'Wael Al-Masri',
      slug: 'Wael-Al-Masri',
      indu : 'Marketing',
      title: 'Integrated Marketing Communications Leader of the Year, United Arab Emirates',
   },
   {
      Id: '113',
      tImg: timg113,
      tsImg: tsimg113,
      name: 'Wolfgang Loerli',
      slug: 'Wolfgang-Loerli',
      indu : 'Technology',
      com:'SOORT AG',
      title: 'Most Innovative Company of the Year, Switzerland',
   },
   {
      Id: '114',
      tImg: timg114,
      tsImg: tsimg114,
      name: 'Zahra Sbeih',
      slug: 'Zahra-Sbeih',
      indu : 'Human Resources',
      title: 'Young Entrepreneur of the Year, Lebanon',
   }   
]

export default Teams;