import React from 'react'
import { Link } from 'react-router-dom'
import Teams from '../../api/Teams'
import shape1 from '../../images/background/pattern-23.png'
// import shape2 from '../../images/background/7.jpg'
import shape3 from '../../images/background/pattern-5.png'

const Speakers3 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="speakers-three" id='speakers'>
            <div className="speakers-three_pattern" style={{ backgroundImage: `url(${shape1})` }}></div>
            {/* <div className="speakers-three_image" style={{ backgroundImage: `url(${shape2})` }}></div> */}
            <div className="auto-container">
                <div className="sec-title light title-anim centered">
                    <h2 className="sec-title_heading">2024 Global Edition <span style={{color:'#c99c19',}}>Guests of Honor</span></h2>
                    {/* <div className="sec-title_title" style={{ marginTop:'20px' }}><Link onClick={ClickHandler} to="/">Join us </Link> at this excellence awards program in extending a warm welcome to our distinguished special invitees.</div> */}
                </div>
                <div className="row clearfix" style={{justifyContent: 'center'}}>

                    {Teams.slice(0, 12).map((team, aitem) => (
                        <div className="speaker-block_three col-lg-3 col-md-6 col-sm-12" key={aitem}>
                            <div className="speaker-block_three-inner">
                                <div className="speaker-block_three-image">
                                    <img src={team.tsImg} alt="" />
                                    <div className="speaker-block_three-pattern" style={{ backgroundImage: `url(${shape3})` }}></div>
                                    {/* <div className="speaker-block_three-social">
                                        <span className="speaker-block_three-share fa fa-share-alt"></span>
                                        <div className="speaker-block_three-social-list">
                                            <Link onClick={ClickHandler} to={`/speaker-details/${team.slug}`} className="facebook fa fa-facebook"></Link>
                                            <Link onClick={ClickHandler} to={`/speaker-details/${team.slug}`} className="youtube fi flaticon-youtube"></Link>
                                            <Link onClick={ClickHandler} to={`/speaker-details/${team.slug}`} className="twitter fa fa-brands fa-twitter"></Link>
                                        </div>
                                    </div> */}
                                    <div className="speaker-block_three-content" style={{ marginLeft: '-20px'}}>
                                        <h4 className="speaker-block_three-title" style={{ marginTop: '-20px' }}><a href={team.slug}>{team.name}</a></h4>
                                        {/* <h4 className="speaker-block_three-title" style={{ marginTop: '-20px' , color:}}></h4> */}
                                        <div className="speaker-block_three-detail">{team.title}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* <div class="about-one_button" style={{textAlign: 'center'}}><a class="theme-btn btn-style-one" href="/special-invitees/"><span class="btn-wrap"><span class="text-one">Show More</span><span class="text-two">Show More</span></span></a></div> */}
                </div>
            </div>
        </section>
    )
}

export default Speakers3;
