import timg from '../images/resource/Dr.-Ebrahim-Al-Alkeem.jpg'
import timg0 from '../images/resource/MiCheal-Conner-Hey-Mann.jpg'
import timg1 from '../images/resource/Yaqoob-Al-Ali-s.jpg'
import timg3 from '../images/resource/Sultan-Alameri-s.jpg'
import timg4 from '../images/resource/Moran-Cerf-s.jpg'
import timg5 from '../images/resource/Linda-Salvin-s.jpg'
import timg8 from '../images/resource/Amit-SHeth-s.jpg'
import timg12 from '../images/resource/Eng.-Ahmed-Al-Hosani.jpg'

import tsimg from '../images/resource/Dr.-Ebrahim-Al-Alkeem.jpg'
import tsimg0 from '../images/resource/MiCheal-Conner-Hey-Mann.jpg'
import tsimg1 from '../images/resource/Yaqoob-Al-Ali-s.jpg'
import tsimg3 from '../images/resource/Sultan-Alameri-s.jpg'
import tsimg4 from '../images/resource/Moran-Cerf-s.jpg'
import tsimg5 from '../images/resource/Linda-Salvin-s.jpg'
import tsimg8 from '../images/resource/Amit-SHeth-s.jpg'
import tsimg12 from '../images/resource/Eng.-Ahmed-Al-Hosani.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Yaqoob Al Ali',
      slug: "javascript:void(0)",
      title: "Executive Director & Private Advisor, Office of His Highness Sheikh Juma Bin Maktoum Al Maktoum, UAE",
   },
   {
      Id: '2',
      tImg: timg,
      tsImg: tsimg,
      name: 'Dr. Ebrahim Al Alkeem',
      slug: 'https://linkedin.com/in/dr-ebrahim-al-alkeem-al-zaabi-ph-d-95599617/',
      title: "National Risk & Policy Director, Executive Office of AML & CTF, Government of UAE",
   },
   {
      Id: '3',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Sultan Alameri',
      slug: 'https://linkedin.com/in/sultan-alameri-b1798873',
      title: 'Deputy Vice Chancellor, Emirates College for Advanced Education, UAE',
   },
   {
      Id: '4',
      tImg: timg12,
      tsImg: tsimg12,
      name: 'Eng. Ahmed Al Hosani',
      slug: 'https://linkedin.com/in/singer-ahmed-alhosani-8a329a231',
      title: "UAE's First Classical Singer, Emirati Musicians' Association",
   },
   {
      Id: '5',
      tImg: timg8,
      tsImg: tsimg8,
      name: 'Amit Sheth',
      slug: 'javascript:void(0)',
      title: 'Award-winning Serial Entrepreneur & Philanthropist, India',
   },
   {
      Id: '6',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr. Moran Cerf',
      slug: 'https://linkedin.com/in/morancerf',
      title: 'Professor of Neuroscience & Business, Columbia University, USA',
   },
   {
      Id: '7',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Dr. Linda Salvin',
      slug: 'https://linkedin.com/in/linda-salvin-mph-phd-85009411/',
      title: 'MPH, PHD, Veteran Radio Talk Show Host, Healer and Metaphysician, Creator of Wicks of Wisdom, USA',
   },
   {
      Id: '8',
      tImg: timg0,
      tsImg: tsimg0,
      name: 'Dr. Michael Conner',
      slug: "https://www.linkedin.com/in/michael-conner-ed-d-82033a21/",
      title: "CEO/Founder @ Agile Evolutionary Group, USA",
   },

]

export default Teams;