import timg1 from '../images/winner/Aadil-Shah.jpg'
import timg2 from '../images/winner/Adil-Sajwani.jpg'
import timg3 from '../images/winner/Ahmed-Abusham.jpg'
import timg4 from '../images/winner/Ahmed-Magdy-Rabea.jpg'
import timg5 from '../images/winner/ALfred-shihata.jpg'
import timg6 from '../images/winner/Amarjit-Singh.jpg'
import timg7 from '../images/winner/Dr.-Amal-Alhefdhi.jpg'
import timg8 from '../images/winner/Anna-barcanda.jpg'
import timg9 from '../images/winner/Anna-Langridge.jpg'
import timg10 from '../images/winner/Annique-Clopon.jpg'
import timg11 from '../images/winner/Anu-Sutaria.jpg'
import timg12 from '../images/winner/Arjan-Salih-Dawod.jpg'
import timg13 from '../images/winner/Arun-kumar-singh.jpg'
import timg14 from '../images/winner/Burzin-Khan.jpg'
import timg15 from '../images/winner/Prof.-Carlos.jpg'
import timg16 from '../images/winner/Caroline-purvey-2.jpg'
import timg17 from '../images/winner/Caroline-purvey.jpg'
import timg18 from '../images/winner/Chinten-Shah.jpg'
import timg19 from '../images/winner/Chris-sulowsk.jpg'
import timg20 from '../images/winner/Christine-Colburn.jpg'
import timg21 from '../images/winner/Corinne-Beckar-lang.jpg'
import timg22 from '../images/winner/Dani-afioiuni.jpg'
import timg23 from '../images/winner/Eisuke.jpg'
import timg24 from '../images/winner/emmi.jpg'
import timg25 from '../images/winner/Eittene-Hendrixx.jpg'
import timg26 from '../images/winner/Evangelia-bakali.jpg';
import timg27 from '../images/winner/Ewelina-turk.jpg'
import timg28 from '../images/winner/Frederic-Roscorp.jpg'
import timg29 from '../images/winner/Frederic-Roscorp.jpg'
import timg30 from '../images/winner/Gauranga-Das.jpg'
import timg31 from '../images/winner/Geetika.jpg';
import timg32 from '../images/winner/Godfrey-Mushandu.jpg';
import timg33 from '../images/winner/Guru-prasad-painuly.jpg';
import timg34 from '../images/winner/hasan-Ali.jpg';
import timg35 from '../images/winner/Hassan-Elba.jpg';
import timg36 from '../images/winner/Holger-Sudhoff.jpg';
import timg37 from '../images/winner/Jaber-Syed.jpg';
import timg38 from '../images/winner/James-Martin.jpg';
import timg39 from '../images/winner/John-pereira.jpg';
import timg40 from '../images/winner/John-pereira.jpg';
import timg41 from '../images/winner/jon.jpg';
import timg42 from '../images/winner/Josep-soler.jpg';
import timg43 from '../images/winner/Joshua-manoharan.jpg';
import timg44 from '../images/winner/Julia-Linn.jpg';
import timg45 from '../images/winner/Julie-harrigan.jpg';
import timg46 from '../images/winner/Ketan-Amin.jpg';
import timg47 from '../images/winner/Kevin-Lau.jpg';
import timg48 from '../images/winner/Khalid-Master.jpg';
import timg49 from '../images/winner/Kiran-kaur.jpg';
import timg50 from '../images/winner/laura.jpg';
import timg51 from '../images/winner/Les-Kalman.jpg';
import timg52 from '../images/winner/Luca-gualdi.jpg';
import timg53 from '../images/winner/Mahesh-Desai.jpg';
import timg54 from '../images/winner/Mahira-Chisty.jpg';
import timg55 from '../images/winner/Mai-elwy.jpg';
import timg56 from '../images/winner/Manoj-Balaji.jpg';
import timg57 from '../images/winner/Mita-vinay.jpg';
import timg58 from '../images/winner/Mohammed-Algamal.jpg';
import timg59 from '../images/winner/solwa.jpg';
import timg60 from '../images/winner/Nabhit-Kapur.jpg';
import timg61 from '../images/winner/Nachiketa-sinha.jpg';
import timg62 from '../images/winner/Nadine-singh.jpg';
import timg63 from '../images/winner/Medlabs---Nael-Soudi.jpg';
import timg64 from '../images/winner/Najib-khan.jpg';
import timg65 from '../images/winner/Nate-zeleznick.jpg';
import timg66 from '../images/winner/Nathan-Mcnally.jpg';
import timg67 from '../images/winner/neha-Ranglani.jpg';
import timg68 from '../images/winner/Niraj-Gupta.jpg';
import timg69 from '../images/winner/Nuzhat-ahsan.jpg';
import timg70 from '../images/winner/osseiny-samgwa.jpg';
import timg71 from '../images/winner/Pam-Cushing.jpg';
import timg72 from '../images/winner/prasanna-chachan.jpg';
import timg73 from '../images/winner/Praveen-gupta.jpg';
import timg74 from '../images/winner/Rajbir-kaur.jpg';
import timg75 from '../images/winner/Rajesh-kesavan.jpg';
import timg76 from '../images/winner/the_mind.jpg';
import timg77 from '../images/winner/Ritu-Khare.jpg';
import timg78 from '../images/winner/Robert-Goddard.jpg';
import timg79 from '../images/winner/Ryan-Harvey.jpg';
import timg80 from '../images/winner/Stephanie-Wyborn.jpg';
import timg81 from '../images/winner/Salam-ghoussaini.jpg';
import timg82 from '../images/winner/Sameet-Gill.jpg';
import timg83 from '../images/winner/Sameet-Gill.jpg';
import timg84 from '../images/winner/Sarvesh-melvin.jpg';
import timg85 from '../images/winner/sateesh.jpg';
import timg86 from '../images/winner/seema-shah.jpg';
import timg87 from '../images/winner/medcare.jpg';
import timg88 from '../images/winner/Shraddha-sheth.jpg';
import timg89 from '../images/winner/Simran-kaur.jpg';
import timg90 from '../images/winner/SIvakumaran.jpg';
import timg91 from '../images/winner/Soonick-seow.jpg';
import timg92 from '../images/winner/Steven-victor.jpg';
import timg93 from '../images/winner/Teresa.jpg';
import timg94 from '../images/winner/Vijay-uttarwar.jpg';
import timg95 from '../images/winner/Wijdan-Alomaim.jpg';
import timg96 from '../images/winner/william-lee.jpg';
import timg97 from '../images/winner/William-Money.jpg';
import timg98 from '../images/winner/Zeina-ghousaini.jpg';


import tsimg1 from '../images/winner/Aadil-Shah.jpg'
import tsimg2 from '../images/winner/Adil-Sajwani.jpg'
import tsimg3 from '../images/winner/Ahmed-Abusham.jpg'
import tsimg4 from '../images/winner/Ahmed-Magdy-Rabea.jpg'
import tsimg5 from '../images/winner/ALfred-shihata.jpg'
import tsimg6 from '../images/winner/Amarjit-Singh.jpg'
import tsimg7 from '../images/winner/Dr.-Amal-Alhefdhi.jpg'
import tsimg8 from '../images/winner/Anna-barcanda.jpg'
import tsimg9 from '../images/winner/Anna-Langridge.jpg'
import tsimg10 from '../images/winner/Annique-Clopon.jpg'
import tsimg11 from '../images/winner/Anu-Sutaria.jpg'
import tsimg12 from '../images/winner/Arjan-Salih-Dawod.jpg'
import tsimg13 from '../images/winner/Arun-kumar-singh.jpg'
import tsimg14 from '../images/winner/Burzin-Khan.jpg'
import tsimg15 from '../images/winner/Prof.-Carlos.jpg'
import tsimg16 from '../images/winner/Caroline-purvey-2.jpg'
import tsimg17 from '../images/winner/Caroline-purvey.jpg'
import tsimg18 from '../images/winner/Chinten-Shah.jpg'
import tsimg19 from '../images/winner/Chris-sulowsk.jpg'
import tsimg20 from '../images/winner/Christine-Colburn.jpg'
import tsimg21 from '../images/winner/Corinne-Beckar-lang.jpg'
import tsimg22 from '../images/winner/Dani-afioiuni.jpg'
import tsimg23 from '../images/winner/Eisuke.jpg'
import tsimg24 from '../images/winner/emmi.jpg'
import tsimg25 from '../images/winner/Eittene-Hendrixx.jpg'
import tsimg26  from '../images/winner/Evangelia-bakali.jpg';
import tsimg27 from '../images/winner/Ewelina-turk.jpg'
import tsimg28 from '../images/winner/Frederic-Roscorp.jpg'
import tsimg29 from '../images/winner/Frederic-Roscorp.jpg'
import tsimg30 from '../images/winner/Gauranga-Das.jpg'
import tsimg31 from '../images/winner/Geetika.jpg';
import tsimg32 from '../images/winner/Godfrey-Mushandu.jpg';
import tsimg33 from '../images/winner/Guru-prasad-painuly.jpg';
import tsimg34 from '../images/winner/hasan-Ali.jpg';
import tsimg35 from '../images/winner/Hassan-Elba.jpg';
import tsimg36 from '../images/winner/Holger-Sudhoff.jpg';
import tsimg37 from '../images/winner/Jaber-Syed.jpg';
import tsimg38 from '../images/winner/James-Martin.jpg';
import tsimg39 from '../images/winner/John-pereira.jpg';
import tsimg40 from '../images/winner/John-pereira.jpg';
import tsimg41 from '../images/winner/jon.jpg';
import tsimg42 from '../images/winner/Josep-soler.jpg';
import tsimg43 from '../images/winner/Joshua-manoharan.jpg';
import tsimg44 from '../images/winner/Julia-Linn.jpg';
import tsimg45 from '../images/winner/Julie-harrigan.jpg';
import tsimg46 from '../images/winner/Ketan-Amin.jpg';
import tsimg47 from '../images/winner/Kevin-Lau.jpg';
import tsimg48 from '../images/winner/Khalid-Master.jpg';
import tsimg49 from '../images/winner/Kiran-kaur.jpg';
import tsimg50 from '../images/winner/laura.jpg';
import tsimg51 from '../images/winner/Les-Kalman.jpg';
import tsimg52 from '../images/winner/Luca-gualdi.jpg';
import tsimg53 from '../images/winner/Mahesh-Desai.jpg';
import tsimg54 from '../images/winner/Mahira-Chisty.jpg';
import tsimg55 from '../images/winner/Mai-elwy.jpg';
import tsimg56 from '../images/winner/Manoj-Balaji.jpg';
import tsimg57 from '../images/winner/Mita-vinay.jpg';
import tsimg58 from '../images/winner/Mohammed-Algamal.jpg';
import tsimg59 from '../images/winner/solwa.jpg';
import tsimg60 from '../images/winner/Nabhit-Kapur.jpg';
import tsimg61 from '../images/winner/Nachiketa-sinha.jpg';
import tsimg62 from '../images/winner/Nadine-singh.jpg';
import tsimg63 from '../images/winner/Medlabs---Nael-Soudi.jpg';
import tsimg64 from '../images/winner/Najib-khan.jpg';
import tsimg65 from '../images/winner/Nate-zeleznick.jpg';
import tsimg66 from '../images/winner/Nathan-Mcnally.jpg';
import tsimg67 from '../images/winner/neha-Ranglani.jpg';
import tsimg68 from '../images/winner/Niraj-Gupta.jpg';
import tsimg69 from '../images/winner/Nuzhat-ahsan.jpg';
import tsimg70 from '../images/winner/osseiny-samgwa.jpg';
import tsimg71 from '../images/winner/Pam-Cushing.jpg';
import tsimg72 from '../images/winner/prasanna-chachan.jpg';
import tsimg73 from '../images/winner/Praveen-gupta.jpg';
import tsimg74 from '../images/winner/Rajbir-kaur.jpg';
import tsimg75 from '../images/winner/Rajesh-kesavan.jpg';
import tsimg76 from '../images/winner/the_mind.jpg';
import tsimg77 from '../images/winner/Ritu-Khare.jpg';
import tsimg78 from '../images/winner/Robert-Goddard.jpg';
import tsimg79 from '../images/winner/Ryan-Harvey.jpg';
import tsimg80 from '../images/winner/Stephanie-Wyborn.jpg';
import tsimg81 from '../images/winner/Salam-ghoussaini.jpg';
import tsimg82 from '../images/winner/Sameet-Gill.jpg';
import tsimg83 from '../images/winner/Sameet-Gill.jpg';
import tsimg84 from '../images/winner/Sarvesh-melvin.jpg';
import tsimg85 from '../images/winner/sateesh.jpg';
import tsimg86 from '../images/winner/seema-shah.jpg';
import tsimg87 from '../images/winner/medcare.jpg';
import tsimg88 from '../images/winner/Shraddha-sheth.jpg';
import tsimg89 from '../images/winner/Simran-kaur.jpg';
import tsimg90 from '../images/winner/SIvakumaran.jpg';
import tsimg91 from '../images/winner/Soonick-seow.jpg';
import tsimg92 from '../images/winner/Steven-victor.jpg';
import tsimg93 from '../images/winner/Teresa.jpg';
import tsimg94 from '../images/winner/Vijay-uttarwar.jpg';
import tsimg95 from '../images/winner/Wijdan-Alomaim.jpg';
import tsimg96 from '../images/winner/william-lee.jpg';
import tsimg97 from '../images/winner/William-Money.jpg';
import tsimg98 from '../images/winner/Zeina-ghousaini.jpg';



const Teams = [
   {
      Id: '1',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Aadil Shah',
      slug: 'Aadil-Shah',
      title: 'Founder of the Year, India',
   },
   {
      Id: '2',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Dr. Adil Saeed Sajwani',
      slug: 'Dr-Adil-Saeed-Sajwani',
      title: 'Healthcare Influencer of the Year, UAE',
   },
   {
      Id: '3',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Dr. Ahmed A. Abusham',
      slug: 'Dr-Ahmed-A-Abusham',
      title: 'Clinical Mentor of the Year, Oman',
   },
   {
      Id: '4',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Ahmed Magdy Rabea',
      slug: 'Ahmed-Magdy-Rabea',
      title: 'Oncologist of the Year, Egypt',
   },
   {
      Id: '5',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Dr. Alfred Shihata',
      slug: 'Dr-Alfred-Shihata',
      com: 'FemCap',
      title: 'Product of the Year - Contraceptive , United States',
   },
   {
      Id: '6',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Dr. (Brig) Amarjit Singh',
      slug: 'Dr-Amarjit-Singh',
      title: 'Lifetime Achievement Award, India',
   },
   {
      Id: '7',
      tImg: timg7,
      tsImg: tsimg7,
      name: 'Dr. Amal Alhefdhi',
      slug: 'Dr-Amal-Alhefdhi',
      title: 'Most Inspiring Leader of the Year, Saudi Arabia',
   },
   {
      Id: '8',
      tImg: timg8,
      tsImg: tsimg8,
      name: 'Dr. Anna Barnacka',
      slug: 'Dr-Anna-Barnacka',
      com: 'MindMics',
      title: 'Wellness Product of the Year, United States',
   },
   {
      Id: '9',
      tImg: timg9,
      tsImg: tsimg9,
      name: 'Anna Langridge',
      slug: 'Anna-Langridge',
      title: 'Director of the Year, United Kingdom',
   },
   {
      Id: '10',
      tImg: timg10,
      tsImg: tsimg10,
      name: 'Dr. Annique Clopon',
      slug: 'Dr-Annique-Clopon',
      title: 'Psychologist of the Year, United Kingdom',
   },
   {
      Id: '11',
      tImg: timg11,
      tsImg: tsimg11,
      name: 'Anu Sutaria',
      slug: 'Anu-Sutaria',
      title: 'Fundraising Leader of the Year, India',
   },
   {
      Id: '12',
      tImg: timg12,
      tsImg: tsimg12,
      name: 'Arjan Salih Dawod',
      slug: 'Arjan-Salih-Dawod',
      title: 'Healthcare Leader of the Year, Iraq',
   },
   {
      Id: '13',
      tImg: timg13,
      tsImg: tsimg13,
      name: 'Dr. Arun Kumar Singh',
      slug: 'Dr-Arun-Kumar-Singh',
      title: 'Lifetime Achievement Award in Healthcare Governance, India',
   },
   {
      Id: '14',
      tImg: timg14,
      tsImg: tsimg14,
      name: 'Dr. Burzin Khan',
      slug: 'Dr-Burzin-Khan',
      title: 'Digital Restorative Dentist of the Year, India',
   },
   {
      Id: '15',
      tImg: timg15,
      tsImg: tsimg15,
      name: 'Prof. Carlos Rivas Echeverría',
      slug: 'Prof-Carlos-Rivas-Echeverria',
      title: 'Physician of the Year, United Kingdom',
   },
   {
      Id: '16',
      tImg: timg16,
      tsImg: tsimg16,
      name: 'Dr. Caroline Purvey',
      slug: 'Dr-Caroline-Purvey',
      title: 'Healthcare Education Leader of the Year, United Kingdom',
   },
   {
      Id: '17',
      tImg: timg17,
      tsImg: tsimg17,
      name: 'Chema Fernandez De La Vega',
      slug: 'Chema-Fernandez-De-La-Vega',
      title: 'Young Wellness Leader of the Year, Spain',
   },
   {
      Id: '18',
      tImg: timg18,
      tsImg: tsimg18,
      name: 'Chinten Shah',
      slug: 'Chinten-Shah',
      com: 'Ekyamm',
      title: 'Mental Health Start-up of the Year, India',
   },
   {
      Id: '19',
      tImg: timg19,
      tsImg: tsimg19,
      name: 'Dr. Chris Sulowski',
      slug: 'Dr-Chris-Sulowski',
      title: 'Aesthetic Consultant of the Year, Canada',
   },
   {
      Id: '20',
      tImg: timg20,
      tsImg: tsimg20,
      name: 'Christine Colburn',
      slug: 'Christine-Colburn',
      title: 'Child Advocacy & Therapy Leader of the Year, United States',
   },
   {
      Id: '21',
      tImg: timg21,
      tsImg: tsimg21,
      name: 'Dr. Corinne Becker Lang',
      slug: 'Dr-Corinne-Becker-Lang',
      title: 'Lifetime Achievement Award, France',
   },
   {
      Id: '22',
      tImg: timg22,
      tsImg: tsimg22,
      name: 'Dani Afiouni',
      slug: 'Dani-Afiouni',
      com: 'Longevity Wellness Hub',
      title: 'Marketing Company of the Year, UAE',
   },
   {
      Id: '23',
      tImg: timg23,
      tsImg: tsimg23,
      name: 'Dr. Eisuke Shimizu',
      slug: 'Dr-Eisuke-Shimizu',
      title: 'Healthcare Leader of the Year, Japan',
   },
   {
      Id: '24',
      tImg: timg24,
      tsImg: tsimg24,
      name: 'Emi Schemmer',
      slug: 'Emi-Schemmer',
      com: 'MOSAIK',
      title: 'Emerging Skincare Product of the Year, Japan',
   },
   {
      Id: '25',
      tImg: timg25,
      tsImg: tsimg25,
      name: 'Etienne Hendrickx',
      slug: 'Etienne-Hendrickx',
      com: 'Shape Accelerator',
      title: 'Product of the Year, Netherlands',
   },
   {
      Id: '26',
      tImg: timg26,
      tsImg: tsimg26,
      name: 'Dr. Evangelia Bakali',
      slug: 'Dr-Evangelia-Bakali',
      title: 'Gynecologist of the Year, United Kingdom',
   },
   {
      Id: '27',
      tImg: timg27,
      tsImg: tsimg27,
      name: 'Dr. Ewelina Türk',
      slug: 'Dr-Ewelina-Türk',
      title: 'Healthcare Leader of the Year, Switzerland',
   },
   {
      Id: '28',
      tImg: timg28,
      tsImg: tsimg28,
      name: 'Dr. Frederic Roscop',
      slug: 'Dr-Frederic-Roscop',
      com:'Aequil',
      title: 'Well-being Start-up of the Year, United Kingdom',
      title2: 'Osteopath of the Year, United Kingdom',
   },
   // {
   //    Id: '29',
   //    tImg: timg29,
   //    tsImg: tsimg29,
   //    name: 'Dr. Frederic Roscop',
   //    slug: 'Dr-Frederic-Roscop',
   //    title: 'Well-being Start-up of the Year, United Kingdom',
   // },
   {
      Id: '30',
      tImg: timg30,
      tsImg: tsimg30,
      name: 'Gauranga Das',
      slug: 'Gauranga-Das',
      title: 'Spiritual Leader, Sustainability Advocate, and Social Innovator',
   },
   {
      Id: '31',
      tImg: timg31,
      tsImg: tsimg31,
      name: 'Dr. Geetika Gupta',
      slug: 'Dr-Geetika-Gupta',
      com:'ISAAC LUXE',
      title: 'Aesthetic/Cosmetic Surgery Centre of the Year, India',
   },
   {
      Id: '32',
      tImg: timg32,
      tsImg: tsimg32,
      name: 'Godfrey Mushandu',
      slug: 'Godfrey-Mushandu',
      com:'Care Quality Support and Ultra Healthcare Solutions',
      title: 'Healthcare Consultancy of the Year, United Kingdom',
   },
   {
      Id: '33',
      tImg: timg33,
      tsImg: tsimg33,
      name: 'Dr. Guru Prasad Painuly',
      slug: 'Dr-Guru-Prasad-Painuly',
      title: 'General Surgeon of the Year, India',
   },
   {
      Id: '34',
      tImg: timg34,
      tsImg: tsimg34,
      name: 'Dr. Hasan Ali',
      slug: 'Dr-Hasan-Ali',
      title: 'Cosmetic Plastic Surgeon of the Year, UAE',
   },
   {
      Id: '35',
      tImg: timg35,
      tsImg: tsimg35,
      name: 'Hassan Elbarbary',
      slug: 'Hassan-Elbarbary',
      com:'Your Health Group',
      title: 'Healthcare Company of the Year, United Kingdom',
   },
   {
      Id: '36',
      tImg: timg36,
      tsImg: tsimg36,
      name: 'Dr. Holger Sudhoff',
      slug: 'Dr-Holger-Sudhoff',
      title: 'Otolaryngologist of the Year, Germany',
   },
   {
      Id: '37',
      tImg: timg37,
      tsImg: tsimg37,
      name: 'Jaber Syed',
      slug: 'Jaber-Syed',
      title: 'Wellness Leader of the Year, Saudi Arabia',
   },
   {
      Id: '38',
      tImg: timg38,
      tsImg: tsimg38,
      name: 'James Martin',
      slug: 'James-Martin',
      com:'ezClinic',
      title: 'Emerging Healthcare Start-up of the Year, United States',
   },
   {
      Id: '39',
      tImg: timg39,
      tsImg: tsimg39,
      name: 'Dr. John Pereira',
      slug: 'Dr-John-Pereira',
      com:'FibroFOCUS',
      title: 'Pain Management Program of the Year, Canada',
      title2: 'Pain Management Specialist of the Year, Canada',
   },
   {
      Id: '41',
      tImg: timg41,
      tsImg: tsimg41,
      name: 'Jon Wright',
      slug: 'Jon-Wright',
      title: 'Entrepreneur of the Year, United Kingdom',
   },
   {
      Id: '42',
      tImg: timg42,
      tsImg: tsimg42,
      name: 'Dr. Josep Miquel Soler',
      slug: 'Dr-Josep-Miquel-Soler',
      title: 'Cardiologist of the Year, Andorra',
   },
   {
      Id: '43',
      tImg: timg43,
      tsImg: tsimg43,
      name: 'Joshua Joel C. Manoharan',
      slug: 'Joshua-Joel-C-Manoharan',
      title: 'Physiotherapist of the Year, Philippines',
   },
   {
      Id: '44',
      tImg: timg44,
      tsImg: tsimg44,
      name: 'Julia Linn',
      slug: 'Julia-Linn',
      title: 'Entrepreneur of the Year, United States',
   },
   {
      Id: '45',
      tImg: timg45,
      tsImg: tsimg45,
      name: 'Dr. Julie Harrigan',
      slug: 'Dr-Julie-Harrigan',
      title: 'CMIO of the Year, United States',
   },
   {
      Id: '46',
      tImg: timg46,
      tsImg: tsimg46,
      name: 'Dr. Ketan Subhashchandra Amin',
      slug: 'Dr-Ketan-Subhashchandra-Amin',
      title: 'Ayurvedic Physician of the Year, India',
   },
   {
      Id: '47',
      tImg: timg47,
      tsImg: tsimg47,
      name: 'Dr. Kevin Lau',
      slug: 'Dr-Kevin-Lau',
      title: 'Chiropractor of the Year, Singapore',
   },
   {
      Id: '48',
      tImg: timg48,
      tsImg: tsimg48,
      name: 'Dr. Khalid Master',
      slug: 'Dr-Khalid-Master',
      title: 'Dentist of the Year, United Kingdom',
   },
   {
      Id: '49',
      tImg: timg49,
      tsImg: tsimg49,
      name: 'Dr. Kiran Kaur Sethi',
      slug: 'Dr-Kiran-Kaur-Sethi',
      com:'Isya Aesthetics',
      title: 'Aesthetic & Wellness Center of the Year, India',
   },
   {
      Id: '50',
      tImg: timg50,
      tsImg: tsimg50,
      name: 'Laura Sierra',
      slug: 'Laura-Sierra',
      title: 'Health Coach of the Year, UAE',
   },
   {
      Id: '51',
      tImg: timg51,
      tsImg: tsimg51,
      name: 'Dr. Les Kalman',
      slug: 'Dr-Les-Kalman',
      title: 'Restorative Dentist of the Year, Canada',
   },
   {
      Id: '52',
      tImg: timg52,
      tsImg: tsimg52,
      name: 'Dr. Luca Gualdi',
      slug: 'Dr-Luca-Gualdi',
      title: 'Ophthalmologist of the Year, Italy',
   },
   {
      Id: '53',
      tImg: timg53,
      tsImg: tsimg53,
      name: 'Dr. Mahesh Desai',
      slug: 'Dr-Mahesh-Desai',
      com:'Muljibhai Patel Urological Hospital',
      title: 'Single Specialty Hospital of the Year, India',
   },
   {
      Id: '54',
      tImg: timg54,
      tsImg: tsimg54,
      name: 'Mahira Chishty-Zakiuddin',
      slug: 'Mahira-Chishty-Zakiuddin',
      title: 'Counsellor of the Year, UAE',
   },
   {
      Id: '55',
      tImg: timg55,
      tsImg: tsimg55,
      name: 'Mai Elwy',
      slug: 'Mai-Elwy',
      title: 'Mental Health Professional of the Year, Egypt',
   },
   {
      Id: '56',
      tImg: timg56,
      tsImg: tsimg56,
      name: 'Manoj Balaji',
      slug: 'Manoj-Balaji',
      com:'TatvaCare',
      title: 'Healthcare Start-up of the Year, India',
   },
   {
      Id: '57',
      tImg: timg57,
      tsImg: tsimg57,
      name: 'Mita Vinay',
      slug: 'Mita-Vinay',
      title: 'Yoga Coach of the Year, India',
   },
   {
      Id: '58',
      tImg: timg58,
      tsImg: tsimg58,
      name: 'Mohamed Algamal',
      slug: 'Mohamed-Algamal',
      title: 'CEO of the Year, UAE',
   },
   {
      Id: '59',
      tImg: timg59,
      tsImg: tsimg59,
      name: 'Muhammad Solwa',
      slug: 'Muhammad-Solwa',
      title: 'Chief Audit Executive (CAE) of the Year, Qatar',
   },
   {
      Id: '60',
      tImg: timg60,
      tsImg: tsimg60,
      name: 'Dr. Nabhit Kapur',
      slug: 'Dr-Nabhit-Kapur',
      title: 'Visionary Founder of the Year, India',
   },
   {
      Id: '61',
      tImg: timg61,
      tsImg: tsimg61,
      name: 'Dr. Nachiketa Sinha',
      slug: 'Dr-Nachiketa-Sinha',
      title: 'Consultant Psychiatrist and Senior Physician of the Year, Canada',
   },
   {
      Id: '62',
      tImg: timg62,
      tsImg: tsimg62,
      name: 'Dr. Nadine Singh',
      slug: 'Dr-Nadine-Singh',
      title: 'Healthcare Entrepreneur of the Year, United States',
   },
   {
      Id: '63',
      tImg: timg63,
      tsImg: tsimg63,
      name: 'MedLabs Consultancy Group',
      slug: 'MedLabs-Consultancy-Group',
      title: 'Emerging Healthcare Company of the Year, Jordan',
   },
   {
      Id: '64',
      tImg: timg64,
      tsImg: tsimg64,
      name: 'Najib Khan',
      slug: 'Najib-Khan',
      com:'NAMAYOU',
      title: 'Holistic Nutrition and Lifestyle Program of the Year, United Kingdom',
   },
   {
      Id: '65',
      tImg: timg65,
      tsImg: tsimg65,
      name: 'Nate Zeleznick',
      slug: 'Nate-Zeleznick',
      title: 'Wellness Coach of the Year, United States',
   },
   {
      Id: '66',
      tImg: timg66,
      tsImg: tsimg66,
      name: 'Nathan McNally',
      slug: 'Nathan-McNally',
      title: 'Healthcare Technology Leader of the Year, United Kingdom',
   },
   {
      Id: '67',
      tImg: timg67,
      tsImg: tsimg67,
      name: 'Neha Ranglani',
      slug: 'Neha-Ranglani',
      title: 'Integrative Health Coach of the Year, India',
   },
   {
      Id: '68',
      tImg: timg68,
      tsImg: tsimg68,
      name: 'Dr. Niraj Gupta',
      slug: 'Dr-Niraj-Gupta',
      title: 'Cardiologist of the Year, India',
   },
   {
      Id: '69',
      tImg: timg69,
      tsImg: tsimg69,
      name: 'Dr. Nuzhat Ahsan',
      slug: 'Dr-Nuzhat-Ahsan',
      title: 'Healthcare Education Leader of the Year, UAE',
   },
   {
      Id: '70',
      tImg: timg70,
      tsImg: tsimg70,
      name: 'Dr. Osseiny Samgwa',
      slug: 'Dr-Osseiny-Samgwa',
      title: 'Healthcare Leader of the Year, Cameroon',
   },
   {
      Id: '71',
      tImg: timg71,
      tsImg: tsimg71,
      name: 'Pam Cushing',
      slug: 'Pam-Cushing',
      title: 'Innovative Product Distributor of the Year, United Kingdom',
   },
   {
      Id: '72',
      tImg: timg72,
      tsImg: tsimg72,
      name: 'Dr. Prasanna Chachan',
      slug: 'Dr-Prasanna-Chachan',
      com:'Pinnacle Pharma',
      title: 'Pharma Company of the Year, UAE',
   },
   {
      Id: '73',
      tImg: timg73,
      tsImg: tsimg73,
      name: 'Dr. Praveen Gupta',
      slug: 'Dr-Praveen-Gupta',
      title: 'Neurologist of the Year, India',
   },
   {
      Id: '74',
      tImg: timg74,
      tsImg: tsimg74,
      name: 'Rajbir Kaur',
      slug: 'Rajbir-Kaur',
      title: 'Physiotherapist of the Year, UAE',
   },
   {
      Id: '75',
      tImg: timg75,
      tsImg: tsimg75,
      name: 'Dr. Rajesh Kesavan',
      slug: 'Dr-Rajesh-Kesavan',
      title: 'Podiatrist of the Year, India',
   },
   {
      Id: '76',
      tImg: timg76,
      tsImg: tsimg76,
      name: 'The Mind Research Foundation',
      slug: 'The-Mind-Research-Foundation',
      title: 'Mental Healthcare Center of the Year, India',
   },
   {
      Id: '77',
      tImg: timg77,
      tsImg: tsimg77,
      name: 'Dr. Ritu Khare',
      slug: 'Dr-Ritu-Khare',
      title: 'Minimally Invasive Surgeon of the Year, UAE',
   },
   {
      Id: '78',
      tImg: timg78,
      tsImg: tsimg78,
      name: 'Robert Goddard',
      slug: 'Robert-Goddard',
      title: 'Facial Surgeon of the Year, United Kingdom',
   },
   {
      Id: '79',
      tImg: timg79,
      tsImg: tsimg79,
      name: 'Dr. Ryan Harvey',
      slug: 'Dr-Ryan-Harvey',
      title: 'Skin Cancer Specialist of the Year, Australia',
   },
   {
      Id: '80',
      tImg: timg80,
      tsImg: tsimg80,
      name: 'Stephanie Wyborn',
      slug: 'Stephanie-Wyborn',
      title: 'Innovative Idea of the Year, New Zealand',
   },
   {
      Id: '81',
      tImg: timg81,
      tsImg: tsimg81,
      name: 'Salam El Ghoussaini',
      slug: 'Salam-El-Ghoussaini',
      title: 'Wellness Leader of the Year, UAE',
   },
   {
      Id: '82',
      tImg: timg82,
      tsImg: tsimg82,
      name: 'Dr. Sameet Gill',
      slug: 'Dr-Sameet-Gill',
      title: 'Dentist of the Year, Canada',
      title2: 'Young Dentist of the Year, Global',
   },
   {
      Id: '84',
      tImg: timg84,
      tsImg: tsimg84,
      name: 'Sarvesh Melvin',
      slug: 'Sarvesh-Melvin',
      title: 'Young Wellness Leader of the Year, Singapore',
   },
   {
      Id: '85',
      tImg: timg85,
      tsImg: tsimg85,
      name: 'Dr. Satheesh Kumar Reddy C',
      slug: 'Dr-Satheesh-Kumar-Reddy-C',
      title: 'Chief Product Officer of the Year, India',
   },
   {
      Id: '86',
      tImg: timg86,
      tsImg: tsimg86,
      name: 'Seema Shah',
      slug: 'Seema-Shah',
      title: 'CEO of the Year, Canada',
   },
   {
      Id: '87',
      tImg: timg87,
      tsImg: tsimg87,
      name: 'Medcare Hospital Sharjah',
      slug: 'Medcare-Hospital-Sharjah',
      title: 'Hospital of the Year, UAE',
   },
   {
      Id: '88',
      tImg: timg88,
      tsImg: tsimg88,
      name: 'Shraddha Sheth',
      slug: 'Shraddha-Sheth',
      title: 'Fitness Mentor of the Year, India',
   },
   {
      Id: '89',
      tImg: timg89,
      tsImg: tsimg89,
      name: 'Dr. Simran Kaur',
      slug: 'Dr-Simran-Kaur',
      title: 'Dentist of the Year, UAE',
   },
   {
      Id: '90',
      tImg: timg90,
      tsImg: tsimg90,
      name: 'Dr. Sivakumaran Janakiraman',
      slug: 'Dr-Sivakumaran-Janakiraman',
      title: 'COO of the Year, India',
   },
   {
      Id: '91',
      tImg: timg91,
      tsImg: tsimg91,
      name: 'Soonick Seow',
      slug: 'Soonick-Seow',
      com:'Wellbeing Pharmacy',
      title: 'Pharmacy Retail Chain of the Year, United Kingdom',
   },
   {
      Id: '92',
      tImg: timg92,
      tsImg: tsimg92,
      name: 'Dr. Steven Victor',
      slug: 'Dr-Steven-Victor',
      com:'Victor Longevity',
      title: 'Clinic Chain of the Year, UAE',
   },
   {
      Id: '93',
      tImg: timg93,
      tsImg: tsimg93,
      name: 'Dr. Teresa Castiello',
      slug: 'Dr-Teresa-Castiello',
      title: 'Cardiologist of the Year, United Kingdom',
   },
   {
      Id: '94',
      tImg: timg94,
      tsImg: tsimg94,
      name: 'Vijay Uttarwar',
      slug: 'Vijay-Uttarwar',
      com:'RiteBite Max Protein',
      title: 'Protein Snack of the Year, India',
   },
   {
      Id: '95',
      tImg: timg95,
      tsImg: tsimg95,
      name: 'Dr. Wijdan Alomaim',
      slug: 'Dr-Wijdan-Alomaim',
      title: 'Educator of the Year, UAE',
   },
   {
      Id: '96',
      tImg: timg96,
      tsImg: tsimg96,
      name: 'Dr. William Lee',
      slug: 'Dr-William-Lee',
      title: 'President of the Year, United States',
   },
   {
      Id: '97',
      tImg: timg97,
      tsImg: tsimg97,
      name: 'William Money',
      slug: 'William-Money',
      title: 'Healthcare Staffing Leader of the Year, United States',
   },
   {
      Id: '98',
      tImg: timg98,
      tsImg: tsimg98,
      name: 'Dr. Zeina Ghossoub al Asswad',
      slug: 'Dr-Zeina-Ghossoub-al-Asswad',
      title: 'Wellness Leader of the Year, United States',
   }
]

export default Teams;