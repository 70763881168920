import React from "react";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

// Import images
import pImg1 from '../../images/gallery/1h.jpg';
import pImg2 from '../../images/gallery/2h.jpg';
import pImg3 from '../../images/gallery/3h.jpg';
import pImg4 from '../../images/gallery/4h.jpg';
import pImg5 from '../../images/gallery/5h.jpg';
import pImg6 from '../../images/gallery/6h.jpg';
import pImg7 from '../../images/gallery/7h.jpg';
import pImg8 from '../../images/gallery/8h.jpg';
import pImg9 from '../../images/gallery/9h.jpg';
import pImg10 from '../../images/gallery/10h.jpg';
import pImg11 from '../../images/gallery/11h.jpg';
import pImg12 from '../../images/gallery/12h.jpg';
import pImg13 from '../../images/gallery/13h.jpg';
import pImg14 from '../../images/gallery/14h.jpg';
import pImg15 from '../../images/gallery/15h.jpg';
import pImg16 from '../../images/gallery/16h.jpg';
import pImg17 from '../../images/gallery/17h.jpg';
import pImg18 from '../../images/gallery/18h.jpg';
import pImg19 from '../../images/gallery/19h.jpg';
import pImg20 from '../../images/gallery/20h.jpg';
import pImg21 from '../../images/gallery/21h.jpg';
import pImg22 from '../../images/gallery/22h.jpg';
import pImg23 from '../../images/gallery/23h.jpg';
import pImg24 from '../../images/gallery/24h.jpg';
import pImg25 from '../../images/gallery/25h.jpg';
import pImg26 from '../../images/gallery/26h.jpg';
import pImg27 from '../../images/gallery/27h.jpg';
import pImg28 from '../../images/gallery/28h.jpg';
import pImg29 from '../../images/gallery/29h.jpg';
import pImg30 from '../../images/gallery/30h.jpg';
import pImg31 from '../../images/gallery/31h.jpg';
import pImg32 from '../../images/gallery/32h.jpg';
import pImg33 from '../../images/gallery/33h.jpg';
import pImg34 from '../../images/gallery/34h.jpg';
import pImg35 from '../../images/gallery/35h.jpg';
import pImg36 from '../../images/gallery/36h.jpg';
import pImg37 from '../../images/gallery/37h.jpg';
import pImg38 from '../../images/gallery/38h.jpg';
import pImg39 from '../../images/gallery/39h.jpg';
import pImg40 from '../../images/gallery/40h.jpg';
import pImg41 from '../../images/gallery/41h.jpg';
import pImg42 from '../../images/gallery/42h.jpg';
import pImg43 from '../../images/gallery/43h.jpg';
import pImg44 from '../../images/gallery/44h.jpg';
import pImg45 from '../../images/gallery/45h.jpg';
import pImg46 from '../../images/gallery/46h.jpg';
import pImg47 from '../../images/gallery/47h.jpg';
import pImg48 from '../../images/gallery/48h.jpg';
import pImg49 from '../../images/gallery/49h.jpg';
import pImg50 from '../../images/gallery/50h.jpg';
import pImg51 from '../../images/gallery/51h.jpg';
import pImg52 from '../../images/gallery/52h.jpg';
import pImg53 from '../../images/gallery/53h.jpg';
import pImg54 from '../../images/gallery/54h.jpg';
import pImg55 from '../../images/gallery/55h.jpg';
import pImg56 from '../../images/gallery/56h.jpg';
import pImg57 from '../../images/gallery/57h.jpg';
import pImg58 from '../../images/gallery/58h.jpg';
import pImg59 from '../../images/gallery/59h.jpg';
import pImg60 from '../../images/gallery/60h.jpg';
import pImg61 from '../../images/gallery/61h.jpg';
import pImg62 from '../../images/gallery/62h.jpg';
import pImg63 from '../../images/gallery/63h.jpg';
import pImg64 from '../../images/gallery/64h.jpg';
import pImg65 from '../../images/gallery/65h.jpg';
import pImg66 from '../../images/gallery/66h.jpg';
import pImg67 from '../../images/gallery/67h.jpg';
import pImg68 from '../../images/gallery/68h.jpg';
import pImg69 from '../../images/gallery/69h.jpg';
import pImg70 from '../../images/gallery/70h.jpg';
import pImg71 from '../../images/gallery/71h.jpg';
import pImg72 from '../../images/gallery/72h.jpg';
import pImg73 from '../../images/gallery/73h.jpg';
import pImg74 from '../../images/gallery/74h.jpg';
import pImg75 from '../../images/gallery/75h.jpg';
import pImg76 from '../../images/gallery/76h.jpg';
import pImg77 from '../../images/gallery/77h.jpg';
import pImg78 from '../../images/gallery/78h.jpg';
import pImg79 from '../../images/gallery/79h.jpg';
import pImg80 from '../../images/gallery/80h.jpg';
import pImg81 from '../../images/gallery/81h.jpg';
import pImg82 from '../../images/gallery/82h.jpg';
import pImg83 from '../../images/gallery/83h.jpg';
import pImg84 from '../../images/gallery/84h.jpg';
import pImg85 from '../../images/gallery/85h.jpg';
import pImg86 from '../../images/gallery/86h.jpg';
import pImg87 from '../../images/gallery/87h.jpg';
import pImg88 from '../../images/gallery/88h.jpg';
import pImg89 from '../../images/gallery/89h.jpg';
import pImg90 from '../../images/gallery/90h.jpg';
import pImg91 from '../../images/gallery/91h.jpg';
import pImg92 from '../../images/gallery/92h.jpg';
import pImg93 from '../../images/gallery/93h.jpg';
import pImg94 from '../../images/gallery/94h.jpg';
import pImg95 from '../../images/gallery/95h.jpg';
import pImg96 from '../../images/gallery/96h.jpg';
import pImg97 from '../../images/gallery/97h.jpg';
import pImg98 from '../../images/gallery/98h.jpg';
import pImg99 from '../../images/gallery/99h.jpg';
import pImg100 from '../../images/gallery/100h.jpg';
import pImg101 from '../../images/gallery/101h.jpg';
import pImg102 from '../../images/gallery/102h.jpg';
import pImg103 from '../../images/gallery/103h.jpg';
import pImg104 from '../../images/gallery/104h.jpg';
import pImg105 from '../../images/gallery/105h.jpg';
import pImg106 from '../../images/gallery/106h.jpg';
import pImg107 from '../../images/gallery/107h.jpg';
import pImg108 from '../../images/gallery/108h.jpg';
import pImg109 from '../../images/gallery/109h.jpg';
import pImg110 from '../../images/gallery/110h.jpg';
import pImg111 from '../../images/gallery/111h.jpg';
import pImg112 from '../../images/gallery/112h.jpg';
import pImg113 from '../../images/gallery/113h.jpg';
import pImg114 from '../../images/gallery/114h.jpg';
import pImg115 from '../../images/gallery/115h.jpg';
import pImg116 from '../../images/gallery/116h.jpg';
import pImg117 from '../../images/gallery/117h.jpg';
import pImg118 from '../../images/gallery/118h.jpg';
import pImg119 from '../../images/gallery/119h.jpg';
import pImg120 from '../../images/gallery/120h.jpg';
import pImg121 from '../../images/gallery/121h.jpg';
import pImg122 from '../../images/gallery/122h.jpg';
import pImg123 from '../../images/gallery/123h.jpg';
import pImg124 from '../../images/gallery/124h.jpg';
import pImg125 from '../../images/gallery/125h.jpg';
import pImg126 from '../../images/gallery/126h.jpg';
import pImg127 from '../../images/gallery/127h.jpg';
import pImg128 from '../../images/gallery/128h.jpg';
import pImg129 from '../../images/gallery/129h.jpg';
import pImg130 from '../../images/gallery/130h.jpg';
import pImg131 from '../../images/gallery/131h.jpg';
import pImg132 from '../../images/gallery/132h.jpg';
import pImg133 from '../../images/gallery/133h.jpg';
import pImg134 from '../../images/gallery/134h.jpg';
import pImg135 from '../../images/gallery/135h.jpg';
import pImg136 from '../../images/gallery/136h.jpg';
import pImg137 from '../../images/gallery/137h.jpg';
import pImg138 from '../../images/gallery/138h.jpg';
import pImg139 from '../../images/gallery/139h.jpg';
import pImg140 from '../../images/gallery/140h.jpg';
import pImg141 from '../../images/gallery/141h.jpg';
import pImg142 from '../../images/gallery/142h.jpg';
import pImg143 from '../../images/gallery/143h.jpg';
import pImg144 from '../../images/gallery/144h.jpg';
import pImg145 from '../../images/gallery/145h.jpg';
import pImg146 from '../../images/gallery/146h.jpg';
import pImg147 from '../../images/gallery/147h.jpg';
import pImg148 from '../../images/gallery/148h.jpg';
import pImg149 from '../../images/gallery/149h.jpg';
import pImg150 from '../../images/gallery/150h.jpg';

// Add all your imports...

// Array of images
const images = [
    pImg1, pImg2, pImg3, pImg4, pImg5, pImg6, pImg7, pImg8, pImg9, pImg10,
    pImg11, pImg12, pImg13, pImg14, pImg15, pImg16, pImg17, pImg18, pImg19, pImg20,
    pImg21, pImg22, pImg23, pImg24, pImg25, pImg26, pImg27, pImg28, pImg29, pImg30,
    pImg31, pImg32, pImg33, pImg34, pImg35, pImg36, pImg37, pImg38, pImg39, pImg40,
    pImg41, pImg42, pImg43, pImg44, pImg45, pImg46, pImg47, pImg48, pImg49, pImg50,
    pImg51, pImg52, pImg53, pImg54, pImg55, pImg56, pImg57, pImg58, pImg59, pImg60,
    pImg61, pImg62, pImg63, pImg64, pImg65, pImg66, pImg67, pImg68, pImg69, pImg70,
    pImg71, pImg72, pImg73, pImg74, pImg75, pImg76, pImg77, pImg78, pImg79, pImg80,
    pImg81, pImg82, pImg83, pImg84, pImg85, pImg86, pImg87, pImg88, pImg89, pImg90,
    pImg91, pImg92, pImg93, pImg94, pImg95, pImg96, pImg97, pImg98, pImg99, pImg100,
    pImg101, pImg102, pImg103, pImg104, pImg105, pImg106, pImg107, pImg108, pImg109, pImg110,
    pImg111, pImg112, pImg113, pImg114, pImg115, pImg116, pImg117, pImg118, pImg119, pImg120,
    pImg121, pImg122, pImg123, pImg124, pImg125, pImg126, pImg127, pImg128, pImg129, pImg130,
    pImg131, pImg132, pImg133, pImg134, pImg135, pImg136, pImg137, pImg138, pImg139, pImg140,
    pImg141, pImg142, pImg143, pImg144, pImg145, pImg146, pImg147, pImg148, pImg149, pImg150
];
    

// Convert images to slides
const slides = images.map((image) => ({ src: image }));

const GallerySection3 = () => {
    const [open, setOpen] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(0);

    // Handle image click
    const handleImageClick = (index) => {
        setCurrentIndex(index); // Set the index of the clicked image
        setOpen(true); // Open the lightbox
    };

    return (
        <section className="gallery-three">
            <div className="auto-container">
                <div className="row clearfix">
                    {images.map((item, index) => (
                        <div
                            className="gallery-one_block col-lg-4 col-md-4 col-sm-6"
                            key={index}
                        >
                            <div className="gallery-one_block-inner">
                                <div className="gallery-one_block-image">
                                    <img src={item} alt={`Gallery Image ${index + 1}`} />
                                    {/* <div className="gallery-one_block-overlay">
                                        <span className="gallery-one_block-icon flaticon-plus-symbol"></span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={slides} // Use the slides array
                currentIndex={currentIndex} // Show the clicked image
                plugins={[Zoom]}
            />
        </section>
    );
};

export default GallerySection3;

