import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom'
import pIcon1 from '../../images/background/Jury.jpg';
import Navbar from '../../components/Navbar/Navbar';
import AllJuryIndia from '../../components/AllJuryIndia/AllJuryIndia';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer2 from '../../components/footer2/Footer2';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';

const SpeakerPage = (props) => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | India Edition | The Jury';
    }, []);
    return (
        <><Helmet>
        <meta name="description" content="The Forttuna Global Excellence Awards Jury is comprised of a select group of individuals who are committed to the pursuit of excellence." />
        <meta property="og:title" content="Forttuna Global Excellence Awards | India Edition | The Jury" />
        <meta property="og:description" content="The Forttuna Global Excellence Awards Jury is comprised of a select group of individuals who are committed to the pursuit of excellence." />
        <meta property="og:image" content={hero1} />
        <meta property="og:url" content={hero1} />
        <meta name="twitter:card" content="summary_large_image" />
    </Helmet>   
        <Fragment>
            <Navbar />
            <section className="page-title banner-jury" >
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">The Jury</div>
                            <h2 className="page-title_heading">The Jury</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Jury</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <AllJuryIndia/>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default SpeakerPage;
