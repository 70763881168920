import React from "react";
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import hero0 from '../../images/main-slider/Global-Event-Banner.jpg'
import hero0m from '../../images/main-slider/Global-Event.webp'
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.jpg'
import hero1m from '../../images/main-slider/Homepage-Banner-Dubai.jpg'
import hero2 from '../../images/main-slider/Merit-Based-Banner.jpg'
import hero2m from '../../images/main-slider/Merit-Based.webp'
import hero3 from '../../images/main-slider/Jury-Led-Banner.jpg'
import hero3m from '../../images/main-slider/Jury-Lead.webp'
import hero4 from '../../images/main-slider/Gala-Night-Banner.jpg'
import hero4m from '../../images/main-slider/Gala-Night.webp'
import hero5 from '../../images/main-slider/Networking-Banner2.jpg'
import hero5m from '../../images/main-slider/Networking.webp'
import ptn from '../../images/main-slider/pattern-2.png'
import ptn2 from '../../images/main-slider/pattern-1.png'

const Hero = () => {

    var settings = {
        dots: true,
        arrows: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
    };
    const backgroundImageStyle0 = {
        backgroundImage: `url(${hero0})`,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        transform: 'scale(1)',
        transition: 'all 6000ms linear'
    };
    const backgroundImageStyle0m = {
        backgroundImage: `url(${hero0m})`,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        transform: 'scale(1)',
        transition: 'all 6000ms linear'
    };
    const backgroundImageStyle = {
        backgroundImage: `url(${hero1})`,
        // backgroundImage: `url('https://assets.codepen.io/6093409/river.mp4')`,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        transform: 'scale(1)',
        transition: 'all 6000ms linear'
    };
    const backgroundImageStylem = {
        backgroundImage: `url(${hero1m})`,
        // backgroundImage: `url('https://assets.codepen.io/6093409/river.mp4')`,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        transform: 'scale(1)',
        transition: 'all 6000ms linear'
    };
    const backgroundImageStyle1 = {
        backgroundImage: `url(${hero2})`,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        transform: 'scale(1)',
        transition: 'all 6000ms linear'
    };
    const backgroundImageStyle1m = {
        backgroundImage: `url(${hero2m})`,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        transform: 'scale(1)',
        transition: 'all 6000ms linear'
    };
    const backgroundImageStyle2 = {
        backgroundImage: `url(${hero3})`,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        transform: 'scale(1)',
        transition: 'all 6000ms linear'
    };
    const backgroundImageStyle2m = {
        backgroundImage: `url(${hero3m})`,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        transform: 'scale(1)',
        transition: 'all 6000ms linear'
    };
    const backgroundImageStyle3 = {
        backgroundImage: `url(${hero4})`,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        transform: 'scale(1)',
        transition: 'all 6000ms linear'
    };
    const backgroundImageStyle3m = {
        backgroundImage: `url(${hero4m})`,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        transform: 'scale(1)',
        transition: 'all 6000ms linear'
    };
    const backgroundImageStyle4 = {
        backgroundImage: `url(${hero5})`,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        transform: 'scale(1)',
        transition: 'all 6000ms linear'
    };
    const backgroundImageStyle4m = {
        backgroundImage: `url(${hero5m})`,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        transform: 'scale(1)',
        transition: 'all 6000ms linear'
    };

    return (
        <section className="slider-one" id="home">
            {/* <div className="slider-one_pattern-two" style={{ backgroundImage: `url(${ptn})` }}></div> */}
            <div className="main-slider-carousel">
                <Slider {...settings}>
                    {/*  Slide 01 */}
                    <div className="slider-one_slide">
                        {/* <div className="desk">
                        <video autoPlay loop muted playsInline style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, zIndex: 1, width: '100%', height: '100%', objectFit: 'cover', }} >
                                <source src="https://assets.codepen.io/6093409/river.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div className="mob"> <video autoPlay loop muted playsInline style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, zIndex: 1, width: '100%', height: '100%', objectFit: 'cover', }} >
                                <source src="https://assets.codepen.io/6093409/river.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div> */}
                        <div className="desk" style={backgroundImageStyle}></div>
                        <div className="mob" style={backgroundImageStylem}></div>
                        <div className="slider-one_pattern" style={{ backgroundImage: `url(${ptn2})` }}></div>
                        <div className="auto-container">
                            {/*  Content Column */}
                            <div className="slider-one_content">
                                <div className="slider-one_content-inner">
                                    <div className="slider-one_title">&nbsp;</div>
                                    <div className="slider-one_date">&nbsp;</div>
                                    <h1 className="slider-one_heading">&nbsp;</h1>
                                    <div className="slider-one_buttons">
                                        <a href="https://participate.forttuna.co/" className="theme-btn btn-style-one">
                                            <span className="btn-wrap">
                                                <span className="text-one">Why Participate ?</span>
                                                <span className="text-two">Why Participate ?</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  End Slide 01 */}
                    {/*  Slide 02 */}
                    <div className="slider-one_slide">
                        <div className="desk" style={backgroundImageStyle1}></div>
                        <div className="mob" style={backgroundImageStyle1m}></div>
                        <div className="slider-one_pattern" style={{ backgroundImage: `url(${ptn2})` }}></div>
                        <div className="auto-container">

                            {/*  Content Column */}
                            <div className="slider-one_content">
                                <div className="slider-one_content-inner">
                                    {/* <div className="slider-one_title">North Harond Boston, Canada</div>
                                    <div className="slider-one_date"><span>25</span> March <br /> 2024</div> */}
                                    <h1 className="slider-one_heading">Merit Based  <br /> Recognition</h1>
                                    <div className="slider-one_buttons">
                                        <a href="https://participate.forttuna.co/" className="theme-btn btn-style-one">
                                            <span className="btn-wrap">
                                                <span className="text-one">Why Participate ?</span>
                                                <span className="text-two">Why Participate ?</span>
                                            </span>
                                        </a>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                    {/*  End Slide 02 */}
                    {/*  Slide 03 */}
                    <div className="slider-one_slide">
                        <div className="desk" style={backgroundImageStyle2}></div>
                        <div className="mob" style={backgroundImageStyle2m}></div>
                        <div className="slider-one_pattern" style={{ backgroundImage: `url(${ptn2})` }}></div>
                        <div className="auto-container">

                            {/*  Content Column */}
                            <div className="slider-one_content">
                                <div className="slider-one_content-inner">
                                    <h1 className="slider-one_heading">Jury Led <br/> Initiative</h1>
                                    <div className="slider-one_buttons">
                                        <a href="https://participate.forttuna.co/" className="theme-btn btn-style-one">
                                            <span className="btn-wrap">
                                                <span className="text-one">Why Participate ?</span>
                                                <span className="text-two">Why Participate ?</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider-one_slide">
                        <div className="desk" style={backgroundImageStyle3}></div>
                        <div className="mob" style={backgroundImageStyle3m}></div>
                        <div className="slider-one_pattern" style={{ backgroundImage: `url(${ptn2})` }}></div>
                        <div className="auto-container">

                            {/*  Content Column */}
                            <div className="slider-one_content">
                                <div className="slider-one_content-inner">
                                    <h1 className="slider-one_heading">Awardees Attend  <br /> The Gala Night <br/>At No Cost</h1>
                                    <div className="slider-one_buttons">
                                        <a href="https://participate.forttuna.co/" className="theme-btn btn-style-one">
                                            <span className="btn-wrap">
                                                <span className="text-one">Why Participate ?</span>
                                                <span className="text-two">Why Participate ?</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider-one_slide">
                        <div className="desk" style={backgroundImageStyle0}></div>
                        <div className="mob" style={backgroundImageStyle0m}></div>
                        <div className="slider-one_pattern" style={{ backgroundImage: `url(${ptn2})` }}></div>
                        <div className="auto-container">
                            <div className="slider-one_content">
                                <div className="slider-one_content-inner">
                                    <h1 className="slider-one_heading">Global  <br /> Platform <br/></h1>
                                    <div className="slider-one_buttons">
                                        <a href="https://participate.forttuna.co/" className="theme-btn btn-style-one">
                                            <span className="btn-wrap">
                                                <span className="text-one">Why Participate ?</span>
                                                <span className="text-two">Why Participate ?</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider-one_slide">
                        <div className="desk" style={backgroundImageStyle4}></div>
                        <div className="mob" style={backgroundImageStyle4m}></div>
                        <div className="slider-one_pattern" style={{ backgroundImage: `url(${ptn2})` }}></div>
                        <div className="auto-container">

                            {/*  Content Column */}
                            <div className="slider-one_content">
                                <div className="slider-one_content-inner">
                                    <h1 className="slider-one_heading">Meaningful Networking  <br/>Opportunities</h1>
                                    <div className="slider-one_buttons">
                                        <a href="https://participate.forttuna.co/" className="theme-btn btn-style-one">
                                            <span className="btn-wrap">
                                                <span className="text-one">Why Participate ?</span>
                                                <span className="text-two">Why Participate ?</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Slide 03 */}
                </Slider>

            </div>
        </section>
    )
}




export default Hero;