import React from 'react';

const VideoPlayer = () => {
  return (
    <div className='videoo-div'>
      <video className='videoo'
        width="100%"
        autoPlay
        muted
        loop
        disablePictureInPicture
        controlsList="nodownload"
        // poster="https://peach.blender.org/wp-content/uploads/bbb-splash.png?x81236"
      >
        <source
          src="https://forttuna.co/video/ouraim.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;