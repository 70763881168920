import timg1 from '../images/resource/Amit-Sheth-big.jpg'
import timg2 from '../images/resource/Dr.-Adil-Saeed-Sajwani-big.jpg'
import timg3 from '../images/resource/Dr.-Ebrahim-Al-Alkeem-big.jpg'
import timg4 from '../images/resource/Moran-Cerf-big.jpg'
import timg5 from '../images/resource/Linda-Salvin-big.jpg'
import timg6 from '../images/resource/Mr.-Sultan-Alameri-big.jpg'
import timg7 from '../images/resource/Yaqoob-Al-Ali-big.jpg'
import timg8 from '../images/resource/Subramanyam-Yadavalli-big.jpg'
import timg11 from '../images/resource/Sandra-Matz-big.jpg'
import timg12 from '../images/resource/Eng.-Ahmed-Al-Hosani-big.jpg'

import tsimg1 from '../images/resource/Amit-Sheth-big.jpg'
import tsimg2 from '../images/resource/Dr.-Adil-Saeed-Sajwani-big.jpg'
import tsimg3 from '../images/resource/Dr.-Ebrahim-Al-Alkeem-big.jpg'
import tsimg4 from '../images/resource/Moran-Cerf-big.jpg'
import tsimg5 from '../images/resource/Linda-Salvin-big.jpg'
import tsimg6 from '../images/resource/Mr.-Sultan-Alameri-big.jpg'
import tsimg7 from '../images/resource/Yaqoob-Al-Ali-big.jpg'
import tsimg8 from '../images/resource/Subramanyam-Yadavalli-big.jpg'
import tsimg11 from '../images/resource/Sandra-Matz-big.jpg'
import tsimg12 from '../images/resource/Eng.-Ahmed-Al-Hosani-big.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Dr. Ebrahim Al Alkeem',
      slug: 'https://linkedin.com/in/dr-ebrahim-al-alkeem-al-zaabi-ph-d-95599617/',
      title: 'National Risk & Policy Director, Executive Office of AML & CTF, Government of UAE',
   },
   {
      Id: '2',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Dr. Adil Saeed Sajwani',
      slug: 'https://linkedin.com/in/dr-adil-sajwani',
      title: 'Public Speaker, Healthcare Influencer, Deputy Medical Director, Mediclinic, UAE',
   },
   {
      Id: '3',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Sultan Alameri',
      slug: 'https://linkedin.com/in/sultan-alameri-b1798873',
      title: 'Deputy Vice Chancellor, Emirates College for Advanced Education, UAE',
   },
   {
      Id: '4',
      tImg: timg12,
      tsImg: tsimg12,
      name: 'Eng. Ahmed Al Hosani',
      slug: 'https://linkedin.com/in/singer-ahmed-alhosani-8a329a231',
      title: "UAE's First Classical Singer, Emirati Musicians' Association",
   },
   {
      Id: '5',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr. Moran Cerf',
      slug: 'https://linkedin.com/in/morancerf',
      title: 'Professor of Neuroscience & Business, Columbia University, USA',
   },
   {
      Id: '6',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Dr. Linda Salvin',
      slug: 'https://slinkedin.com/in/linda-salvin-mph-phd-85009411/',
      title: 'MPH, PHD, Veteran Radio Talk Show Host, Healer and Metaphysician, Creator of Wicks of Wisdom, USA',
   },
   {
      Id: '7',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Amit Sheth',
      slug: 'javascript:void(0)',
      title: "Award-winning Serial Entrepreneur & Philanthropist, India",
   },
   {
      Id: '8',
      tImg: timg7,
      tsImg: tsimg7,
      name: 'Yaqoob Al Ali',
      slug: "javascript:void(0)",
      title: 'Executive Director & Private Advisor, Office of His Highness Sheikh Juma Bin Maktoum Al Maktoum, UAE',
   },
   {
      Id: '9',
      tImg: timg8,
      tsImg: tsimg8,
      name: 'Subramanyam Yadavalli',
      slug: 'https://linkedin.com/in/subramanyam-yadavalli-6ba30059',
      title: 'CEO, Apollo Hospitals, AP & International, India',
   },
   // {
   //    Id: '10',
   //    tImg: timg9,
   //    tsImg: tsimg9,
   //    name: 'Ravi Tharoor',
   //    slug: 'https://linkedin.com/in/ravi-tharoor-5375486',
   //    title: 'Former CEO, Khaleej Times, UAE',
   // },
   {
      Id: '11',
      tImg: timg11,
      tsImg: tsimg11,
      name: 'Prof. Dr. Sandra Matz',
      slug: 'https://linkedin.com/in/sandra-matz-6824742b',
      title: 'Professor, Columbia Business School, USA',
   },
   // {
   //    Id: '12',
   //    tImg: timg13,
   //    tsImg: tsimg13,
   //    name: 'Armin Shahriari',
   //    slug: 'https://linkedin.com/in/armin-shahriari',
   //    title: "Deputy Secretary General and CEO of HTDC Accelerator, Iran",
   // },

]

export default Teams;