import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import codee from '../../images/background/capture.webp';

const ContactForm = () => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: '',
        recaptcha: ''
    });

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const [forceUpdate, setForceUpdate] = useState(false);

    // Predefined CAPTCHA value
    const correctCaptcha = '4#a780';

    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
    };

    const submitHandler = e => {
        e.preventDefault();

        // Check if all validations pass
        if (validator.allValid()) {
            // Explicitly validate CAPTCHA
            if (forms.recaptcha !== correctCaptcha) {
                alert('Incorrect CAPTCHA code. Please try again.');
                return;
            }

            // Create a form dynamically for submission
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = 'https://forttuna.co/mail/';

            // Add all form fields dynamically
            Object.keys(forms).forEach(key => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = forms[key];
                form.appendChild(input);
            });

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);

            // Reset the form state after successful submission
            setForms({
                name: '',
                email: '',
                subject: '',
                phone: '',
                message: '',
                recaptcha: ''
            });
            validator.hideMessages();
            setForceUpdate(prev => !prev); // Force re-render after clearing
        } else {
            validator.showMessages();
            setForceUpdate(prev => !prev); // Force re-render to display validation errors
            alert('Please fill all fields correctly.');
        }
    };

    return (
        <form onSubmit={submitHandler} className='contactForm'>
            <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <div className="form-field">
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Your Name"
                        />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <div className="form-field">
                        <input
                            value={forms.phone}
                            type="text"
                            name="phone"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Your Phone"
                        />
                        {validator.message('phone', forms.phone, 'required|phone')}
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Your Email"
                        />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <div className="form-field">
                        <input
                            value={forms.subject}
                            type="text"
                            name="subject"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Subject"
                        />
                        {validator.message('subject', forms.subject, 'required')}
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                    <textarea
                        onBlur={changeHandler}
                        onChange={changeHandler}
                        value={forms.message}
                        name="message"
                        placeholder="Message"
                    />
                    {validator.message('message', forms.message, 'required')}
                </div>

                <div className="col-lg-3 col-md-12 col-sm-12 form-group">
                    <img src={codee} alt="recaptcha" style={{ width: '155px' }} />
                    <p style={{ fontWeight: 'bold', marginTop: '5px' }}>Code: {correctCaptcha}</p>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <div className="form-field">
                        <input
                            value={forms.recaptcha}
                            type="text"
                            name="recaptcha"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Enter Code..."
                        />
                        {validator.message('recaptcha', forms.recaptcha, 'required')}
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                    <div className="button-box">
                        <button className="btn-style-one theme-btn" type="submit">
                            <div className="btn-wrap">
                                <span className="text-one">Submit Now</span>
                                <span className="text-two">Submit Now</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
